import Axios from './index';

class MartApi {

    // MART SIGN UP
    async MART_SIGN_UP(data) {

        const reponse = await Axios.post('mart/signup', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // Mart SiNGIN 
    async MART_SIGN_IN(data) {

        const reponse = await Axios.post('mart/signin', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }


    // MART NEW ORDERS 
    async GetNewOrders() {

        const reponse = await Axios.get('mart/new_order')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // MART Total ORDERS 
    async GetTotalOrders() {

        const reponse = await Axios.get('mart/my_orders')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET MART ORDERS ITEMS 
    async GetOrderItem(id) {
        const reponse = await Axios.get(`mart/order_details/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET MART DASHBOARD DETAILS 
    async GetDashboardDetails() {
        const reponse = await Axios.get(`mart/dashboard`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }



    //Order To Deliver APis
    async OrderToDeliver(data) {

        const reponse = await Axios.post('mart/order_to_deliver', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }

    // MART NOTIFICATION ORDERS 
    async GetNotificationsOrders() {

        const reponse = await Axios.get('mart/notification_orders')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET MART ORDERS ITEMS 
    async ChangeStatusToPending(id) {
        const reponse = await Axios.get(`mart/accept_notification/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }



}

export default new MartApi();
