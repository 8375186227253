import React from 'react';
import Layout from '../components/layout/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import { Tabs } from 'antd';
import Service from './../components/Service';


const About = () => {
    const { TabPane } = Tabs;
    return (
        <Layout>
            <div className="page_header">
                <h3>About INFO AFFINITY</h3>
            </div>
            <div className="about__section">
                <Container>
                    <Row>
                        <Col md={10} className="mx-auto">
                            <Row>
                                <Col md={8}>
                                    <div className="about_text" data-aos="fade-down" data-aos-duration="1000">
                                        <h3>About Us</h3>
                                        <p>We are tech oriented company providing real time solutions to the complex
                                            businesses problems to bring ease in public life and create source of employment
                                            in various forms.  The Info Affinity is thriving to change you imagination into implementation.
                                            We provide digital services and on demand product development.
                                            Work on National and International level make us unique and competent</p>
                                        <ul>
                                            <li>We are tech oriented company providing real time solutions to the complex businesses problems to bring ease in
                                                public life and create source of employment in various forms</li>
                                            <li> The Info Affinity is thriving to change you imagination into implementation</li>
                                            <li>We provide digital services and on demand product development.</li>
                                            <li>Work on National and International level make us unique and competent</li>
                                        </ul>
                                    </div>

                                </Col>
                                <Col md={4}>
                                    <div className="logo_about" data-aos="fade-up" data-aos-duration="1000">
                                        <img src="./../imgs/about_log.png" alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="about__tabs">
                    <Container>
                        <Row>
                            <Col md={10} className="mx-auto">
                                <Tabs defaultActiveKey="1" centered>
                                    <TabPane tab="Vission" key="1">
                                        <Row>
                                            <Col md={8}>
                                                <div className="tab__text" data-aos="slide-right" data-aos-duration="1000">
                                                    <h4>LET US DO IT FOR YOU</h4>
                                                    <ul>
                                                        <li>To Provide convenient services and good digital solution to the customers and businesses while maintaining long
                                                            lasting relationship with our valued customers</li>
                                                        <li>To create unlimited source of employment for people of different areas and countries</li>
                                                        <li>To introduce the concept of work that every individual has the capability to work and learn tech. which help us to
                                                            create unlimited tech professionals.</li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md={4} data-aos="slide-left" data-aos-duration="1000">
                                                <img src="./../imgs/vission.png" className="img-fluid" alt="mission" />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="Mission" key="2">
                                        <Row>
                                            <Col md={8}>
                                                <div className="tab__text">
                                                    <h4>LET US DO IT FOR YOU</h4>
                                                    <ul>
                                                        <li>Our mission is to work on all real world problems and provide digital solutions to them.</li>
                                                        <li>To bring ease in everyone life and save the time of individuals is our keen motivation</li>
                                                        <li>Bringing everything to few clicks and on Door step is our priority</li>
                                                        <li>We aim to provide 360 solution to our customers and users</li>
                                                        <li>We’re expert in digital services and can digitalize any type of business</li>
                                                        <li>We love to automate all the business activities and convince our clients to realize “Let our system do it it for you”.</li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <img src="./../imgs/mission.jpg" className="img-fluid" alt="mission" />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>

                </div>

            </div >


            <Service />

        </Layout >
    )
}

export default About
