import React, { useState, useEffect } from 'react';
import { Spin, Table, Form, Input, Button } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import Http from './../../../Http/MartApi';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';

const MartOrderDetail = () => {

    const { id } = useParams();
    const history = useNavigate();
    const [items, setItems] = useState([]);
    useEffect(() => {
        getItems();
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getItems = () => {
        Http.GetOrderItem(id).then((res) => {
            if (res) {
                setItems(res);
            }
        });
    }





    const updateItem = (e, itemid) => {

        const updateditems = items.map((item) => {
            if (item.id === itemid) {
                item.price = e.target.value;
            }
            const nettotal = item.quantity * item.price;
            item.total = nettotal;
            return item;
        });
        setItems(updateditems);
    }
    // Order Submit Section
    const handleSubmit = (e) => {
        e.preventDefault();

        const finalData = {
            order_id: id,
            data: items
        }
        SubmitData(finalData);

    }

    const SubmitData = (data) => {
        Http.OrderToDeliver(data).then((res) => {
            if (res.success) {
                history('/ven_mart_dash');
            }
        });
    }



    const tableColumn = [
        {
            title: '#',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Description',
            dataIndex: 'item_name',
            key: 'item_name',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: '120px',
            render: (text, record) => <Form.Item name="note" value={record.price} onChange={(e) => updateItem(e, record.id)} >
                <Input />
            </Form.Item>

        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        }
    ];

    const gridData = items.map((tabData, i) => {
        return {
            ...tabData,
            sno: i + 1,
        };
    });

    return (
        <Layout>

            <div className="customer_dashboard">
                <Container>
                    <div className="dashboard_body">
                        <Row>
                            <Col md={{ span: 10, offset: 1 }}>
                                <div className="table_section">
                                    {(!items) ? <Spin /> :
                                        <>
                                            <h5>Order Details</h5>
                                            <Table
                                                columns={tableColumn}
                                                dataSource={gridData}
                                                pagination={false}
                                                rowKey={(record) => record.id}
                                            />
                                            <Button type="primary" className="mt-3" onClick={handleSubmit}>Push To Deliver</Button>

                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </Layout>
    )
}

export default MartOrderDetail
