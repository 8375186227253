import React from 'react'
import Layout from '../components/layout/Layout';
import TopSection from './../components/TopSection';
import Service from './../components/Service';
import Technology from './../components/Technology';
import ProductSction from './../components/ProductSction';
import Product from './../components/Product';

const Home = () => {

    return (
        <Layout>
            {/* <h1>This is HOme</h1> */}
            <TopSection />




            <ProductSction />
            <Technology />

            {/* <Product /> */}


            <Service />


        </Layout>
    )
}

export default Home
