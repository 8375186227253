import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <Container>
                    <Row>
                        <Col md={4} data-aos="flip-left" data-aos-duration="1000">
                            <div className="footer__about">
                                {/* <img src="./../imgs/logo_white.png" alt="logo" className="img-fluid" /> */}
                                <h3 style={{ color: "#29ac47" }}>About Info Affinity</h3>
                                <p>We are tech oriented company providing real time solutions to the complex businesses problems to bring ease in public life and create source of employment in various forms. </p>
                                <ul>
                                    {/* <li><a href="#"><FacebookOutlined /></a></li>
                                    <li><a href="https://www.instagram.com/omniidoor/"><InstagramOutlined /></a></li>
                                    <li><a href="https://www.linkedin.com/in/omniidoor/"><LinkedinOutlined /></a></li>
                                    <li><a href="https://twitter.com/omniidoor"><TwitterOutlined /></a></li> */}
                                    <li><a href="#"><FacebookOutlined /></a></li>
                                    <li><a href="#"><InstagramOutlined /></a></li>
                                    <li><a href="#"><LinkedinOutlined /></a></li>
                                    <li><a href="#"><TwitterOutlined /></a></li>
                                </ul>
                            </div>
                        </Col>

                        {/* <Col md={3} data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                            <div className="footer__product">
                                <h4>Our Products</h4>
                                <ul>
                                    <li><Link to="/productdetail/mart">Grocery & Medicine </Link></li>
                                    <li><Link to="/productdetail/barber">Barbers & Beauty</Link></li>
                                    <li><Link to="/">Health Care</Link></li>
                                    <li><Link to="/productdetail/tutor">Education</Link></li>
                                    <li><Link to="/productdetail/ecom">e-Commerce</Link></li>
                                </ul>
                            </div>
                        </Col> */}

                        <Col md={4} data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                            <div className="footer__usefull">
                                <h4>UseFull Links</h4>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us">about us</Link></li>
                                    <li><Link to="/contact">contact us</Link></li>
                                    <li><Link to="/ourproduct">our product</Link></li>
                                    <li><Link to="/services">serives</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000">
                            <div className="footer__address">
                                <h4>Contact Details</h4>
                                <p><WhatsAppOutlined /> +172 5587 5995</p>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Suite 302, Unit 3, Watling Gate, 297-303  Edgware Road, London, NW9 6NB</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default Footer
