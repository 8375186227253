import React, { useState } from 'react'
import { Row, Col, Navbar, Nav, Container, Offcanvas, Button, Modal } from 'react-bootstrap';
import { MenuFoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import logo from './../../assets/imgs/logo.png';
import { logoutUser } from '../../redux/action/AuthAction';
import { useDispatch } from 'react-redux';

import SignIn from './Signin';
import SigUp from './Signup';

const Header = () => {

    const dispatch = useDispatch();

    // LogOut Function
    const handleLogout = (e) => {
        dispatch(logoutUser());
    }

    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const ModalhandleClose = () => setModal(false);
    const ModalhandleShow = () => setModal(true);
    const [form, setForm] = useState(true);
    const FormhandleShow = () => setForm(!form);

    const type = localStorage.getItem('type');
    const vender_type = localStorage.getItem('vender_type');

    let venderDashboard, mobileViewVender;

    if (vender_type && vender_type === 'MART') {
        venderDashboard = (
            <Nav.Link ><Link to="/ven_mart_dash"> Dashboard</Link></Nav.Link>
        )
        mobileViewVender = (
            <li ><Link to="/ven_mart_dash"> Dashboard</Link></li>
        )
    } else if (vender_type && vender_type === 'LABORAROTY') {
        venderDashboard = (
            <Nav.Link ><Link to="/ven_lab_dash"> Dashboard</Link></Nav.Link>
        )
        mobileViewVender = (
            <li ><Link to="/ven_lab_dash"> Dashboard</Link></li>
        )

    } else if (vender_type && vender_type === 'BARBER') {
        venderDashboard = (
            <Nav.Link ><Link to="/ven_barber_dash"> Dashboard</Link></Nav.Link>
        )
        mobileViewVender = (
            <li ><Link to="/ven_barber_dash"> Dashboard</Link></li>
        )
    } else {
        venderDashboard = (
            <Nav.Link ><Link to="/ven_tutor_dash"> Dashboard</Link></Nav.Link>
        )
        mobileViewVender = (
            <li ><Link to="/ven_tutor_dash"> Dashboard</Link></li>
        )
    }


    let navbarView, navbarViewMobile;
    if (type && type === 'Customer') {
        navbarView =
            (
                <>
                    <Nav.Link ><Link to="/">Home</Link></Nav.Link>
                    <Nav.Link ><Link to="/about-us">About Us</Link></Nav.Link>
                    <Nav.Link > <Link to="/services">Services</Link></Nav.Link>
                    {/* <Nav.Link ><Link to="/ourproduct">Our Products</Link></Nav.Link> */}
                    <Nav.Link ><Link to="/contact">Contact Us</Link></Nav.Link>
                    <Nav.Link ><Link to="/customerui">Dashboard</Link></Nav.Link>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
            )
        navbarViewMobile = (
            <>
                <li ><Link to="/">Home</Link></li>
                <li ><Link to="/about-us">About Us</Link></li>
                <li > <Link to="/services">Services</Link></li>
                {/* <li ><Link to="/ourproduct">Our Products</Link></li> */}
                <li ><Link to="/contact">Contact Us</Link></li>
                <li ><Link to="/customerui">Dashboard</Link></li>
                <li onClick={handleLogout}><Link to="" >Logout</Link></li>
            </>
        )
    }
    else if (type && type === 'Vender') {
        navbarView =
            (
                <>
                    <Nav.Link ><Link to="/">Home</Link></Nav.Link>
                    <Nav.Link ><Link to="/about-us">About Us</Link></Nav.Link>
                    <Nav.Link > <Link to="/services">Services</Link></Nav.Link>
                    {/* <Nav.Link ><Link to="/ourproduct">Our Products</Link></Nav.Link> */}
                    <Nav.Link ><Link to="/contact">Contact Us</Link></Nav.Link>
                    {venderDashboard}
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
            )
        navbarViewMobile = (
            <>
                <li ><Link to="/">Home</Link></li>
                <li ><Link to="/about-us">About Us</Link></li>
                <li > <Link to="/services">Services</Link></li>
                {/* <li ><Link to="/ourproduct">Our Products</Link></li> */}
                <li ><Link to="/contact">Contact Us</Link></li>

                {mobileViewVender}
                <li onClick={handleLogout}><Link to="" >Logout</Link></li>
            </>
        )

    } else {
        navbarView =
            (
                <>
                    <Nav.Link ><Link to="/">Home</Link></Nav.Link>
                    <Nav.Link ><Link to="/about-us">About Us</Link></Nav.Link>
                    <Nav.Link > <Link to="/services">Services</Link></Nav.Link>
                    {/* <Nav.Link ><Link to="/ourproduct">Our Products</Link></Nav.Link> */}
                    <Nav.Link ><Link to="/contact">Contact Us</Link></Nav.Link>
                    <Nav.Link ><Link to="/team">Team</Link></Nav.Link>

                    {/* <Nav.Link ><Link to="/ven_sign_in">Become Partner</Link></Nav.Link> */}
                    {/* <Nav.Link onClick={ModalhandleShow}><Link to="" >Customer</Link></Nav.Link> */}

                </>
            )
        navbarViewMobile = (
            <>
                <li ><Link to="/">Home</Link></li>
                <li ><Link to="/about-us">About Us</Link></li>
                <li > <Link to="/services">Services</Link></li>
                {/* <li ><Link to="/ourproduct">Our Products</Link></li> */}
                <li ><Link to="/contact">Contact Us</Link></li>
                <li ><Link to="/team">Team</Link></li>
                {/* <li ><Link to="/ven_sign_in">Become Partner</Link></li> */}
                {/* <li onClick={ModalhandleShow}><Link to="" >Customer</Link></li> */}
                {/* <li onClick={handleLogout}><Link to="" >Logout</Link></li> */}
            </>
        )
    }


    return (

        <>
            <div className="header__section">
                <Row>
                    <Col md={10} className="mx-auto">
                        <Navbar collapseOnSelect sticky="top" className="modile_display_none">
                            <Container>
                                <Navbar.Brand ><Link to="/"><img src={logo} alt="Logo" width="100" /></Link></Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav ">
                                    <Nav className="mx-auto">
                                        {navbarView}
                                    </Nav>
                                </Navbar.Collapse>

                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </div>

            <Button variant="light" onClick={handleShow} className="mobile_display_block">
                <MenuFoldOutlined />
            </Button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img src={logo} alt="Logo" width="100" /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="mx-auto">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li> <Link to="/services">Services</Link></li>
                        // <li><Link to="/ourproduct">Our Products</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li onClick={ModalhandleShow}><Link to="">SignIn</Link></li> */}
                        {navbarViewMobile}
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>


            <Modal show={modal} onHide={ModalhandleClose}>
                <Modal.Header closeButton style={{ margin: '0 auto' }}>
                    <Modal.Title><img src={logo} alt="Logo" width="150" /></Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <div className="sigin_div">
                        <div className="form__section">
                            {(form) ? <SignIn form={form} /> : <SigUp form={setForm} />}
                            <span onClick={FormhandleShow}>{(form) ? 'Create New Account SingUp..?' : 'I have Already account SignIn'}</span>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    &nbsp;
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Header
