import React from 'react';
import Layout from '../components/layout/Layout';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined, WhatsAppOutlined } from '@ant-design/icons';

const Team = () => {
    const data = [
        {
            name: 'Asim Shah',
            job: 'Chief Executive Officer (CEO)',
            des: ' Founder of Info Affinity. A senior IT consultant and tech oriented person with aim of providing solutions to big businesses problems',
            img: <img src="./imgs/asim.jpeg" alt="" />,
        },
        {
            name: 'Ahmed Shah',
            job: 'Co-founder',
            des: 'Co-Founder of Info Affinity. A visionary & Tech oriented person with high  goals & achievements',
            img: <img src="./imgs/ahmad.jpeg" alt="" />
        },
        {
            name: 'Shahab Younas',
            job: 'Sr. Reac & Node Developer',
            des: 'Software engineer with strong communication & interpersonal skills. Cloud expert Front-end developer. ',
            img: <img src="./imgs/shahab.jpeg" alt="" />
        },
        {
            name: 'Zulfiqar Ali',
            job: 'Sr. Full Stack Developer',
            des: 'An experienced IT professional with dedication and devotion towards work and adapting new technologies. ',
            img: <img src="./imgs/zulfi.png" alt="" />
        },
        {
            name: 'Izaz Ajmid',
            job: 'Mern Stack & React Native developer',
            des: 'Senior React Native developer and MERN Stack Developer. ',
            img: <img src="./imgs/izaz.jpg" alt="" />
        },
        {
            name: 'Kashan Ajmid ',
            job: 'Digital Markiting & SEO',
            des: ' Digital Marketing expert and a good content creator. Team lead with potential management skills.',
            img: <img src="./imgs/kashan.jpg" alt="" />
        },
        {
            name: 'Abdul Ullah Paracha',
            job: 'Big Data Engineer',
            des: ' Big Data engineer and Amazon community builder with experties in automation and data pipelines.',
            img: <img src="./imgs/abd.jpeg" alt="" />
        },
        {
            name: 'Fayaz Khan ',
            job: 'Jr. Web Designer',
            des: 'A passionate web developer with  skills transforming UX/UI to a good responsive frontend. ',
            img: <img src="./imgs/fayaz.jpeg" alt="" />
        },

    ];
    return (
        <Layout>
            <Container className="team-section">
                <div class="header">
                    <div className="title">Our Expert Team</div>
                    <p><small className="text-muted">Our Most Devoted & Dedicated Team </small></p>
                </div>
                <Row>

                    {data.map((item, i) => (
                        <Col sm={12} md={6} lg={3} key={i}>
                            <Card>
                                <Card.Body>
                                    <div className="profile">
                                        {item?.img}
                                    </div>
                                    <div className="card-title"> <h4>{item?.name}</h4> <small>{item?.job}</small> </div>
                                    <div className="card-subtitle">
                                        <p> <small className="text-muted"> {item?.des}</small> </p>
                                    </div>

                                </Card.Body>
                                <Card.Footer>

                                    <ul className="social_media">
                                        <li><a href="#"><FacebookOutlined /></a></li>
                                        <li><a href="#"><InstagramOutlined /></a></li>
                                        <li><a href="#"><LinkedinOutlined /></a></li>
                                        <li><a href="#"><TwitterOutlined /></a></li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Container>

        </Layout>
    )
}

export default Team