import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Typewriter from "typewriter-effect";

const TopSection = () => {



    return (
        <>
            <div className="top__section">
                {/* <Container> */}
                <div className="cover">
                    <div className="text__section" data-aos="fade-in" data-aos-duration="1000">
                        <h1> Welcome To Info Affinity</h1>
                        <h3>

                            <Typewriter
                                options={{
                                    strings: [
                                        'Digitally Yours',
                                        'Digitally at your service 24/7.'
                                    ],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h3>
                        <Link to="/contact" className="guess">Get Started</Link>
                    </div>
                    {/* <div className="__slider">
                        <Carousel autoplay dots={false}>
                            <div className="_slider_item">
                                <h1>Can v help Your Startup To</h1>
                            </div>
                            <div className="_slider_item">
                                <h1>2</h1>
                            </div>
                            <div className="_slider_item">
                                <h1>3</h1>
                            </div>
                            <div className="_slider_item">
                                <h1>4</h1>
                            </div>
                        </Carousel>
                    </div> */}
                </div>
                {/* </Container> */}

            </div>

        </>
    )
}

export default TopSection
