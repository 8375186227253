

import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Http from '../../Http/CustomerApi';

import { useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PaymentDetail = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const history = useNavigate()


    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
        setLoading(!loading)
        const { amount, id, type } = props;

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);

        const result = await stripe.createToken(card);

        if (result.error) {
            setLoading(!loading)
            setError(result.error.message)
            console.log("Error:", result.error.message);
        } else {

            const data = { amount: amount, id: id, type: type, token: result.token.id }

            if (type === "BARBER") {

                Http.CustomerPayToBarber(data).then((res) => {
                    setLoading(!loading)
                    console.log("res data", res)

                    if (res.success) {
                        history("/success")
                    } else {
                        console.log("payment error", res)
                    }
                });

            } else {
                Http.CustomerPayToMart(data).then((res) => {
                    setLoading(!loading)
                    console.log("res data", res)

                    if (res.success) {
                        history("/success")
                    } else {
                        console.log("payment error", res)
                    }
                });

            }


        }

    };



    return (
        <div className="payment_div">
            <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-4">
                    <FormControl name="amount" value={props?.amount} disabled />
                    {/* <FormControl type="hidden" name="id" value={props?.id} /> */}
                </InputGroup>

                <div className="mb-4 paymentinput">
                    <CardElement />
                </div>
                <span className="text-danger">{error ? error : ''}</span>

                <div className="d-grid mb-4">


                    {(!loading) ?
                        <Button onClick={handleSubmit} type="primary" disabled={!stripe}>
                            Pay
                        </Button>
                        :

                        <Button type="primary" disabled>
                            <LoadingOutlined style={{ fontSize: 15 }} spin /> Loading...
                        </Button>

                    }
                </div>
            </Form>
            {/* <button type="submit" className="btn btn-block" style={{ background: "#9e8a78", color: "#fff", borderRadius: "0px" }} disabled={!props.stripe}>Pay</button> */}
        </div>)
};

export default PaymentDetail;
