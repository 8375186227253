import Axios from './index';

class OrderApi {

    async SubmitOrderApi(data) {

        const reponse = await Axios.post('customer/order_for_Specific_mart', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    async SubmitOrderForAnyMart(data) {

        const reponse = await Axios.post('customer/order_near_mart', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }
}

export default new OrderApi();
