
import { SET_CURRENT_USER, USER_LOGOUT } from "./../action/types";


const initialState = {
    isAuthenticated: false,
    user: {},
};

export default function reduc(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        case USER_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            };
        default:
            return state;
    }
}