import React, { useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Rate, message } from 'antd';
import Http from '../../../Http/CustomerApi';
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/layout/Layout';

const CustomerReview = () => {

    const { id } = useParams();
    const history = useNavigate();

    const number = [1, 2, 3, 4, 5];

    const [rate, setrate] = useState(0);
    const [description, setDes] = useState();

    const handleChange = rate => {
        setrate(rate);
    };
    const handleChagne = (e) => {
        const desc = e.target.value;
        setDes(desc);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            "order_id": id,
            "rating": rate,
            "description": description,
        }
        GiveRating(data);
    }

    const GiveRating = (data) => {
        Http.GiveRatingToMart(data).then((res) => {
            if (res.success) {
                message.success(res.message);
                history('/customermartdashboard');
            }
        });
    }

    return (
        <>
            <Layout>

                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">
                            <Row>
                                <Col md={{ span: 5, offset: 4 }}>
                                    <div className="table_section">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3 text-center" controlId="exampleForm.ControlTextarea1">
                                                <Rate tooltips={number} onChange={handleChange} value={rate} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" name="description" onChange={handleChagne}>
                                                <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                            <div className="d-grid gap-2">
                                                <Button variant="primary" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Layout>

        </>
    )
}

export default CustomerReview
