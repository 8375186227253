import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from './../../components/layout/Layout';
import { Link } from 'react-router-dom';

const CustomerUi = () => {
    return (
        <Layout>
            <div className="productSection">
                <Container>
                    <Row>
                        <Col md={8} className="mx-auto">
                            <h1>Choose Your Desired Service</h1>

                            <Row>
                                <Col md={3} data-aos="zoom-in" data-aos-duration="1000">
                                    <Link to="/customermartdashboard">
                                        <Card >
                                            <Card.Img variant="top" src="./../imgs/mart_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Grocery & Medicine</Card.Title>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                                    <Link to="/customerbarberDashboard">
                                        <Card className="">
                                            <Card.Img variant="top" src="./../imgs/barber_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Barbers & Beauty </Card.Title>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
                                    <Link to="/customerlabdashboard">
                                        <Card className="">
                                            <Card.Img variant="top" src="./../imgs/lab_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Health Care</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
                                    <Link to="">
                                        <Card className="hidden_product">
                                            <Card.Img variant="top" src="./../imgs/ecom_icon.png" />
                                            <Card.Body >
                                                <Card.Title>e-Commerce </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>


                                <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">
                                    <Link to="">
                                        <Card className="hidden_product">
                                            <Card.Img variant="top" src="./../imgs/tutor_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Education </Card.Title>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default CustomerUi
