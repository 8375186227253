import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';

const Marker = (props) => {
  const [details, setdetails] = useState(false);

  const ChangeHandle = () => {
    setdetails(!details);
  };

  return (
    <>
      <div>
        <div
          className="pin bounce">
          <FontAwesomeIcon icon={faStore} onMouseOver={ChangeHandle} />
        </div>

        <div className="pulse" />
      </div>
    </>
  );
};

export default Marker;
