import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { LoadingOutlined } from '@ant-design/icons';


import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const LocationInputs = (props) => {

    const [address, setAddress] = useState();

    const handleChange = (address) => {
        setAddress(address);
    }

    const handleSelect = (address, placeId, suggestion) => {
        geocodeByAddress(address)
            .then(results => {
                let latelong = getLatLng(results[0]);

                latelong.then(latelong => {
                    setAddress(suggestion.description);
                    let address = { ...latelong, des: suggestion.description };
                    props.setLocation(address);
                });
                setAddress(address);
            })
            .catch(error => console.error('Error', error));
    };
    return (
        <>
            <PlacesAutocomplete
                value={(address) ? address : ''}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="location_main_div">
                        <InputGroup className="mb-3">
                            <InputGroup.Text><FontAwesomeIcon icon={faMapMarkerAlt} /></InputGroup.Text>
                            <FormControl
                                {...getInputProps({
                                    placeholder: 'Pin Your Location...',
                                    className: 'location-search-input',
                                })}
                            // isValid={props.error}
                            // isInvalid={!props.value}
                            />
                        </InputGroup>
                        <div className="autocomplete-dropdown-container">
                            {loading && <div><LoadingOutlined style={{ fontSize: 12 }} spin /> Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        className="location_span_parent"
                                    >
                                        <span className="location_span_text">{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>

        </>
    )
}

export default LocationInputs
