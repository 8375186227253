export const mart = {
    'title': "Grocery & Medicine",
    'img': './../imgs/mart.png',
    'description': 'Our team of expert professionals is here to help you while handling the complicated communication among trading partners. Just add your business with integration and rest assured of the success that will follow. Experts are here to help! We are very well aware of the requirement to offer solutions and services that are unbeatable and also understand the unavoidable and great role played by employees and staff members in this aspect.this is the reason why we work with professionals who are well skilled in the field they are working.They offer solutions depending on the current industry demands',
    'subdata': [
        {
            'sub_title': 'Customer',
            'sub_img': './../imgs/mart_customer.png',
            'metadata': [
                'The Customer can order his Grocery or Medicine from any near-by Mart/store. By opening the website or App.',
                'Customer can see all the stores in map so he has the choice to place order at specific mart or generate his order and leave it open, all nearest arts will get notification of his order and first accepting mart will get that order.',
                ' We facilitate customers by bringing Marts in competition.'
            ],
        },
        {
            'sub_title': 'Mart',
            'sub_img': './../imgs/mart_mart.png',
            'metadata': [
                'The Marts will pin their location while registering and will be show to all the customers in that area on Map.',
                'The Marts will be notified on each order. They just have to acknowledge the order if they can deliver.',
                'The order is assigned on first come first serve basis.',
                'The marts on our platform will get high sales then the normal.',
            ],
        },
        {
            'sub_title': 'Delivery Service',
            'sub_img': './../imgs/mart_delivry.png',
            'metadata': [
                'The delivery service is connected with the mart and customer.',
                'They get notified with the app when the order is ready to deliver.',
                'The rider is notified on location based if he is in the area of mart so he receives notification and even all the near by Riders are notified',
                'They get the delivery order on first come first serve basis.',
                'The customer detail is automatically updated with order once he accepts it.'
            ],
        }
    ]
};
// Tutor
export const tutor = {
    'title': "Education",
    'img': './../imgs/tutor.png',
    'description': 'The Education is a platform that helps in connecting Students with qalified Tutors. We’re actually the linking platform for helping the students to find his near by tutor on map. The student can both avail the Home Tutoring services and Academy service. The parents can check their reviews and qualification',
    'subdata': [
        {
            'sub_title': 'Student',
            'sub_img': './../imgs/tutor_student.png',
            'metadata': [
                'Student can search can search and see his near-by available tutors and academies by opening the website or Mobile app.',
                'He can book the tutor both for home based tutoring or can join a class in academy.',
            ],
        },
        {
            'sub_title': 'Tutor/Academy',
            'sub_img': './../imgs/tutor_tutor.png',
            'metadata': [
                'The Tutor can work part time or full time by scheduling his availability in his profile.',
                'The academy can offer a specific class to a group of students',
                'The tutor can provide service according to the student schedule also'
            ],
        }
    ]
};

//omni door

export const door = {
    'title': "e-Commerce",
    'img': './../imgs/tutor.png',
    'description': 'The Omni Tutor is a platform that helps in connecting Students with qalified Tutors. We’re actually the linking platform for helping the students to find his near by tutor on map. The student can both avail the Home Tutoring services and Academy service. The parents can check their reviews and qualification',
    'subdata': [
        {
            'sub_title': 'Business to Business-B2B',
            'sub_img': './../imgs/tutor_student.png',
            'metadata': [
                'We are providing e-Commerce platform for Manufacturers where they can list their products for bulk buyers.It will be serving the businesses just like Alibaba',
            ],
        },
        {
            'sub_title': 'Business to Consumer-B2C',
            'sub_img': './../imgs/tutor_tutor.png',
            'metadata': [
                'Our B2C platform will serve the customers in a better way by providing cheap and affordable prices because we aim to bring the manufacturers directly to list the products in market competent prices and with quality assured teams The platform is just like Amazon.',
            ],
        }
    ]
};

// // Barber
export const barber = {
    'title': "Barbers & Beauty",
    'img': './../imgs/barber.png',
    'description': 'We’re the platform providers which links Barbers and Customers. Our platform help barbers to get booked for multiple appointments easily and digitally. All the service providers can work either part time or full time with two type of services Mobile Barbers service or Service at Barber shop.',
    'subdata': [
        {
            'sub_title': 'Barber',
            'sub_img': './../imgs/barber_barber.png',
            'metadata': [
                'The Barber can register himself with his location and he’ll will get available for the customers in near-by places',
            ],
        },
        {
            'sub_title': 'Customer',
            'sub_img': './../imgs/barber_customer.png',
            'metadata': [
                'The customer can choose the barber of his choice and make his appointment to avoid long wait and inconvenience',
            ],
        }
    ]
};