import React, { useState, useEffect } from 'react';
import { Spin, Table, List } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import Http from '../../../Http/CustomerApi';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/layout/Layout';
import moment from 'moment';

const AppointmentDetail = () => {

    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [detail, setDetail] = useState({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getItems() {
        Http.GetBarberOrderItem(id).then((res) => {
            if (res) {
                setDetail(res)
                setItems(res.appointmentdetail);
            }
        });
    }

    useEffect(() => {
        getItems();
    }, [getItems]);







    const tableColumn = [
        {
            title: '#',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Min-Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }
    ];

    const gridData = items.map((tabData, i) => {
        return {
            ...tabData,
            sno: i + 1,
            price: ((tabData || {}).service || {}).price || '',
            title: ((tabData || {}).service || {}).title || '',
            category: ((tabData || {}).service || {}).type || '',
            time: ((tabData || {}).service || {}).minut || ''
        };
    });

    return (
        <Layout>

            <div className="customer_dashboard">
                <Container>
                    <div className="dashboard_body">
                        <h5>Appointment Details</h5>
                        <Row>
                            <Col md={5}>
                                <div className="table_section">

                                    <div className="detail-section">

                                        <List.Item>
                                            <List.Item.Meta
                                                title={'Barber'}
                                                description={detail?.vender?.name}
                                            />
                                            <List.Item.Meta
                                                title={'Contact'}
                                                description={detail?.vender?.contact}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={'Appointment Date'}
                                                description={moment(detail?.start_time).format('DD-MM-YYYY hh:mm a') + ' / ' + moment(detail?.end_time).format('DD-MM-YYYY hh:mm a')}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={'Appointment Type'}
                                                description={detail?.appType}
                                            />
                                            <List.Item.Meta
                                                title={'Serivce Type'}
                                                description={detail?.service_type}
                                            />
                                        </List.Item>
                                    </div>

                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="table_section">
                                    {(!items) ? <Spin /> :
                                        <>
                                            <Table
                                                columns={tableColumn}
                                                dataSource={gridData}
                                                pagination={false}
                                                rowKey={(record) => record.id}
                                            />

                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        </Layout>
    );
};

export default AppointmentDetail;
