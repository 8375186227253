import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { SettingOutlined, ShoppingCartOutlined, DatabaseOutlined, FundProjectionScreenOutlined, CloudOutlined, StarOutlined, ShoppingOutlined, TeamOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const Technology = () => {
    return (
        <>
            <div className="technology__section">
                <div className="heading">
                    <h1 data-aos="zoom-in" data-aos-duration="1000">Specialities & Technologies</h1>
                    <p data-aos="fade-in" data-aos-duration="1000">Our strategists, designers, architects, developers and quality
                        analysts excel in every technology powering native, hybrid,
                        cross-platform and custom software development.</p>
                </div>


                <Container>
                    <Row>
                        <Col md={3} data-aos="zoom-in" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <SettingOutlined />
                                        <h4>Product Engineering</h4>
                                        <p>We have acute expertise in the product development model and agile engineering lifecycle with technical prowess.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <ShoppingCartOutlined />
                                        <h4>E-commerce Solutions</h4>
                                        <p>We offer end-to-end e-commerce software solutions and technical consultation to global clients.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>



                        <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <FundProjectionScreenOutlined />
                                        <h4>Website Development</h4>
                                        <p>The digital domain is now easier than ever! The best website developers are ready to boost your online presence.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col md={3} data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <DatabaseOutlined />
                                        <h4>EDI Services</h4>
                                        <p>EDI solutions help in exchange confidential data between two parties. Attain unrivaled EDI services from our maestros.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col md={3} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <StarOutlined />
                                        <h4>QA & Testing Services</h4>
                                        <p>Our experts are here to assist with Software Quality Assurance Services so that you can meet your business goals.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <ShoppingOutlined />
                                        <h4>Salesforce Development Service</h4>
                                        <p>Acquire optimized services from certified Salesforce Developers for the quality of project implementation.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col md={3} data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <CloudOutlined />
                                        <h4>IT Consulting</h4>
                                        <p>Our skilled IT professionals are here to take care of your IT requirements with cutting-edge technologies.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <TeamOutlined />
                                        <h4>Dedicated Development Team</h4>
                                        <p>Dedicated software developers secure project quality with centralized monitoring to track the design progress.</p>
                                        <Link to="/">Read More..</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>
                </Container>




            </div>

        </>
    )
}

export default Technology
