import { useEffect, useState } from "react";

const CurrentLocation = () => {

    const [location, setLocation] = useState({
        lat: '',
        lng: '',
    });

    const onSuccess = (location) => {

        setLocation({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
        });

    };

    const onError = (error) => {
        setLocation({
            error,
        });

    };

    useEffect(() => {

        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Locatin Not Supported",
            });
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError);

    }, []);

    return location;
}

export default CurrentLocation
