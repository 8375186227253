import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { Row, Col } from 'react-bootstrap';


const MartSection = ({ location, marts }) => {

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="map_div" >
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyDVShzlNwwpUcswCgBEve7t6n9Q3eeD8PI',
                            }}
                            defaultCenter={{
                                lat: 0,
                                lng: 0
                            }}
                            center={{
                                lat: location.lat,
                                lng: location.lng
                            }}
                            defaultZoom={15}
                            yesIWantToUseGoogleMapApiInternals>

                            {marts ? marts.map((data, i) => (
                                <Marker key={i} lat={data.lat} lng={data.lng} data={
                                    {
                                        name: data.name,
                                        id: data.id,
                                        orders: data?.orders[0]?.orders || 0,
                                        rating: data?.ratings[0]?.rating,
                                        reviews: data?.ratings[0]?.reviews || 0,
                                    }
                                } />


                            )) : ''}
                        </GoogleMapReact>
                    </div>
                </Col>

            </Row>


        </>
    )
}

export default MartSection
