import React, { useState, useEffect } from 'react'
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faStar, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Http from '../../../Http/CustomerApi';

const MartOrder = () => {
    const { Text } = Typography;

    const [data, setData] = useState([]);
    useEffect(() => {
        GetData();
    }, []);



    const GetData = () => {
        Http.GetMyOrders().then((res) => {
            if (res) {
                setData(res || []);
            }
        });
    }
    const tableColumn = [
        {
            title: 'Order_Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Mart  Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: ' Pay Method',
            key: 'pay_status',
            render: (text, record) => (
                <>
                    <Text type="secondary">{record.pay_status}</Text>
                    {(record.status === 'Ready_to_Deliver' && record.pay_status != "Paid") ? (
                        <Link to={'/mart-payment/' + record.id} className="btn btn-sm">
                            <FontAwesomeIcon icon={faCreditCard} title="You Want To Pay Online" />
                        </Link>
                    ) : ''}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Link to={'/order_invoice/' + record.id} className="btn  btn-sm">
                        <FontAwesomeIcon icon={faFile} />
                    </Link>

                    {(record.status === 'Completed') ? (
                        <>
                            {record.status === 'Review' ? '' :

                                <Link to={'/customer-review/' + record.id} className="btn  btn-sm">
                                    Review
                                </Link>
                            }
                        </>
                    ) : ''}
                </>
            )


        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).created_at || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        return {
            ...tabData,
            name: ((tabData || {}).vender || {}).name || 'No Assign',
            phone: ((tabData || {}).vender || {}).contact || 'No Assign',
            created_at: formattedDate,
        };
    });
    return (
        <div className="table_section">
            <h4>Mart Orders</h4>
            <Table
                columns={tableColumn}
                dataSource={gridData}
                rowKey={(record) => record.id}
            />

        </div>
    )
}

export default MartOrder
