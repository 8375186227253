import React, { useState } from 'react'
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faLockOpen, faUser, faPhone } from '@fortawesome/free-solid-svg-icons'

import Http from '../../Http/CustomerApi';
import { useNavigate } from 'react-router-dom';
import LocationInputs from './../../components/element/LocationInputs';

const Signup = ({ setForm }) => {

    const history = useNavigate();
    const [values, setValues] = useState({ name: '', email: '', contact: '', address: '', password: '', password_confirmation: '' });
    const [error, setError] = useState([]);
    const [loading, setLoding] = useState(false);
    const [location, setLocation] = useState();

    // On Chage values  Store  Function
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    // Submit Form Function
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoding(true);
        const data = {
            name: values.name,
            email: values.email,
            contact: values.contact,
            password: values.password,
            password_confirmation: values.password_confirmation,
            address: location?.des,
            lat: location?.lat,
            lng: location?.lng,
        }
        SignUpCustomer(data);
    }

    const SignUpCustomer = async (data) => {

        const resp = await Http.CUSTOMER_SIGN_UP(data).then((res) => {
            return res;
        });

        if (resp && resp.success) {
            setLoding(false);
            message.success(resp.message);
            setValues({ name: '', email: '', contact: '', address: '', password: '', password_confirmation: '' });
            // Toster & redirect To Signin
            history('/');
            // setForm(false);

        } else {
            setLoding(false);
            message.error('Ooops Something Goes Wrong, Please Enter valid Data');
            if (resp) {
                setError(resp);
            }
        }
    }

    return (
        <>
            <h4>SIGN UP</h4>
            <Form onSubmit={handleSubmit}>
                <span className="text-danger">{error?.name}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                    <FormControl placeholder="Name" name="name" value={values.name} onChange={handleChange} isValid={values.name} isInvalid={error.name} />
                </InputGroup>

                <span className="text-danger">{error?.email}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                    <FormControl placeholder="info@example.com" name="email" value={values.email} onChange={handleChange} isValid={values.email} isInvalid={error.email} />
                </InputGroup>

                <span className="text-danger">{error?.contact}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text>
                    <FormControl placeholder="+9200000000000" name="contact" value={values.contact} onChange={handleChange} isValid={values.contact} isInvalid={error.contact} />
                </InputGroup>
                <span className="text-danger">{error?.address || error?.lat || error?.lng}</span>
                <LocationInputs setLocation={setLocation} error={error.addrres || error.lat || error.lng} value={location?.des || location?.lat || location?.lng} />
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                    <FormControl id="password" type="password" placeholder="********" onChange={handleChange} name="password" isValid={values.password} isInvalid={error.password} />
                </InputGroup>

                <span className="text-danger">{error?.password}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faLockOpen} /></InputGroup.Text>
                    <FormControl id="password_confirmation" type="password" placeholder="********" name="password_confirmation" value={values.password_confirmation} onChange={handleChange} isValid={values.password} isInvalid={error.password} />
                </InputGroup>
                {(!loading) ?
                    <div className="d-grid">
                        <Button type="submit">
                            SignUp
                        </Button>
                    </div>
                    :

                    <div className="d-grid">
                        <Button type="button" disabled>
                            <LoadingOutlined style={{ fontSize: 15 }} spin /> Loading...
                        </Button>
                    </div>

                }

            </Form>

        </>
    )
}

export default Signup
