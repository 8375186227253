import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout/Layout';
import { barber, mart, door, tutor } from '../components/data';
import { useParams } from "react-router-dom";

const ProductDetail = (props) => {
    let { slug } = useParams();

    let data = "";

    if (slug === 'barber') {
        data = barber;
    } else if (slug === 'ecom') {
        data = door;
    } else if (slug === 'mart') {
        data = mart;
    } else {
        data = tutor;

    }
    return (
        <Layout>
            <div className="page_header">
                <h3>Product Details</h3>
            </div>
            <Container>
                <div className="page_detail">

                    <Row>
                        <Col md={4}>
                            <img src={data.img} alt="title_img" className="img-fluid" />
                        </Col>
                        <Col md={6}>
                            <div className="p_details">
                                <h3>{data.title}</h3>
                                <p>{data.description}</p>
                            </div>
                        </Col>
                    </Row>


                    {data.subdata.map((sub, i) => (
                        (i % 2 === 0) ?
                            <Row className="bg-gray p-4">
                                <Col md={6}>
                                    <div className="p_details">
                                        <h3>{sub.sub_title}</h3>
                                        <ul>
                                            {sub.metadata.map((meta, m) => (
                                                <li>
                                                    {meta}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <img src={sub.sub_img} alt="title_img" className="img-fluid" />
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md={4}>
                                    <img src={sub.sub_img} alt="title_img" className="img-fluid" />
                                </Col>
                                <Col md={6}>
                                    <div className="p_details">
                                        <h3>{sub.sub_title}</h3>
                                        <ul>
                                            {sub.metadata.map((meta, m) => (
                                                <li>
                                                    {meta}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>

                            </Row>
                    ))}





                </div>
            </Container>
        </Layout >
    )
}

export default ProductDetail
