import Axios from './index';

class BarberApi {


    // BARBER Total ORDERS 
    async GetTotalOrders() {

        const reponse = await Axios.get('barber/all_barber_orders')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // MART NEW ORDERS 
    async GetNewBarberOrders() {

        const reponse = await Axios.get('barber/new_barber_orders')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET BARBER ORDERS DETAILS
    async GetBarberOrderItem(id) {
        const reponse = await Axios.get(`barber/new_barber_order_detail/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // CHANGE STATUS TO APPROVE
    async ChangeStatusToApprove(id) {
        const reponse = await Axios.get(`barber/accept_barber_order/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // CHANGE STATUS TO COMPLETE
    async ChangeStatusToComplete(id) {
        const reponse = await Axios.get(`barber/completed_barber_order/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // Get Nearest Barbers 
    async GetNearByBarber(data) {
        const reponse = await Axios.post('customer/get_nearest_barbers', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET Barber Details
    async GetBarberDetail(id) {
        const reponse = await Axios.get(`customer/barber_profile/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // GET Barber Services
    async GetBarberServices() {
        const reponse = await Axios.get(`customer/allservices`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // Give Rating to MartApi
    async GiveRatingToBarber(data) {

        const reponse = await Axios.post('customer/give_barber_rating', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }

    // GET CUSTOMER DASHBOARD DETAILS 
    async GetDashboardDetails() {
        const reponse = await Axios.get(`barber/barber_dashboard`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // Take Appointments
    async TakeAppointment(data) {
        const reponse = await Axios.post('customer/take_appointment_barber', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // Current Date Appointments
    async TakeBarberAppointment(data) {
        const reponse = await Axios.post('customer/take_appointment_barber_current_date', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }




}

export default new BarberApi();
