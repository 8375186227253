import React from 'react';
import GoogleMapReact from 'google-map-react';
import BarberMarker from './BarberMarker';
import { Row, Col } from 'react-bootstrap';

const BarberMap = ({ location, barbers }) => {
    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="map_div" >
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyDVShzlNwwpUcswCgBEve7t6n9Q3eeD8PI',
                            }}
                            defaultCenter={{
                                lat: 0,
                                lng: 0
                            }}
                            center={{
                                lat: location.lat,
                                lng: location.lng
                            }}
                            defaultZoom={15}
                            yesIWantToUseGoogleMapApiInternals>

                            {barbers ? barbers.map((data, i) => (
                                <BarberMarker key={i} lat={data.lat} lng={data.lng} data={
                                    {
                                        name: data.name,
                                        id: data.id,
                                    }
                                } />


                            )) : ''}
                        </GoogleMapReact>
                    </div>
                </Col>

            </Row>

        </>
    )
}

export default BarberMap
