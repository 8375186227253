import React from "react";




const BarberMarker = () => {


    return (
        <>
            <div>
                <div className="pin bounce">
                    {/* <FontAwesomeIcon icon={faMagnet} onMouseOver={ChangeHandle} /> */}
                    <img src="./icon.png" alt="icon" />
                </div>
                <div className="pulse" />

            </div>

        </>
    )
}

export default BarberMarker
