import React from "react";
// import { Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ redirect, component: Component, auth, ...rest }) => {
    const auth1 = auth.isAuthenticated; // determine if authorized, from context or however you're doing it
    return auth1 ? <Outlet /> : <Navigate to={redirect} />;
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
