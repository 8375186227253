import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Spin, Table, } from 'antd';
import Http from './../../../Http/MartApi';
import { useParams } from 'react-router-dom'
import Layout from '../../../components/layout/Layout';

const OrderInvoice = () => {

    const { id } = useParams();
    const [items, setItems] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getItems = () => {
        Http.GetOrderItem(id).then((res) => {
            if (res) {
                setItems(res);
            }
        });
    }
    useEffect(() => {
        getItems();
    }, [getItems, id]);



    const tableColumn = [
        {
            title: '#',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Description',
            dataIndex: 'item_name',
            key: 'item_name',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',


        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        }
    ];

    const gridData = items.map((tabData, i) => {
        return {
            ...tabData,
            sno: i + 1,
        };
    });

    return (
        <>
            <Layout>

                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>
                                    <div className="table_section">
                                        {(!items) ? <Spin /> :
                                            <>
                                                <h5>Order Details</h5>
                                                <Table
                                                    columns={tableColumn}
                                                    dataSource={gridData}
                                                    pagination={false}
                                                    rowKey={(record) => record.id}
                                                />

                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Layout>

        </>
    )
}

export default OrderInvoice
