import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from './../../components/layout/Layout';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
const Success = () => {
    return (
        <Layout>
            <div className="productSection">
                <Container>
                    <Row>
                        <Col md={8} className="mx-auto">
                            <Row>
                                <Col md={8} className="mx-auto">
                                    <Card>
                                        <Card.Img variant="top" src="./../imgs/payment.png" />
                                        <Card.Body>
                                            <h2 className="thank-heading">Thanks You</h2>
                                            <Card.Title>Payment Received Sucessfully</Card.Title>
                                            <p className="thank-text">Thanks for choosing us and shopping by.</p>
                                            <Link to="/customerui">
                                                <Button type="primary" >
                                                    Go To Dashboard
                                                </Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Success;
