import React from 'react'
import Layout from '../../../components/layout/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import MartDashboardData from './MartDashboardData';
import MartOrder from './MartOrder';



const MartDashboard = () => {
    // const [key, setKey] = useState('home');
    return (
        <>
            <Layout>
                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">

                            <Row>
                                <Col>
                                    <MartDashboardData />
                                    <MartOrder />
                                </Col>
                            </Row>

                        </div>
                    </Container>

                </div>
            </Layout>
        </>
    )
}

export default MartDashboard
