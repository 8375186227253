import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/Layout';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
    const [show, setShow] = useState();
    const data = [
        {
            'title': 'Strategic Consulting',
            'heading': 'Why Amazon Web Services?',
            'img': './../imgs/icon/consult.svg',
            'descriptioin': `Convincing someone to use AWS can be challenging, but there are several key points that can help to make the case.
            Cost Savings: One of the biggest benefits of AWS is its pay-as-you-go pricing model, which can help organizations to save significant amounts of money on infrastructure and IT costs. This can be especially appealing for small and medium-sized businesses that may not have the resources to invest in expensive hardware and software.
            Scalability: AWS is highly scalable, which means that organizations can easily increase or decrease their resources as needed, without having to invest in additional hardware. This can be especially beneficial for businesses that experience periods of high demand or rapid growth.
            Security: AWS is a highly secure platform that provides a wide range of security features and compliance certifications. Organizations can be confident that their data and applications are secure, while also meeting regulatory requirements.
            Reliability: AWS is a highly reliable platform that provides a wide range of services and tools to ensure high availability and performance. This can help organizations to minimize downtime and ensure that their applications and services are always available to customers and users.
            Innovation: AWS is constantly innovating and introducing new services and features, which can help organizations to stay ahead of the competition and take advantage of the latest technology trends.
            Flexibility: AWS provides a wide range of services and tools that can be customized and configured to meet the specific needs of any organization. This can help organizations to quickly and easily build and deploy applications and services that meet their unique requirements.
            Global reach: AWS provides a global reach and the ability to run workloads in multiple regions, providing resiliency and disaster recovery options for business critical services.
            By highlighting these key benefits, you can demonstrate to your clients that AWS is a cost-effective, scalable, secure, reliable and flexible platform that can help their business to grow and stay competitive.`
        },
        {
            'title': 'Artificial Intelligence (AI)',
            'heading': 'Artificial Intelligence & Amazon Web Services',
            'img': './../imgs/icon/ai.svg',
            'descriptioin': `AWS offers a wide range of AI services that can help organizations of all sizes to leverage the power of artificial intelligence.
            From image and text analysis to natural language processing and machine learning, AWS has something for everyone.
            Amazon SageMaker
            Amazon SageMaker is a fully-managed service that enables developers and data scientists to build, train, and deploy machine learning models at scale.
            It includes built-in algorithms, pre-built environments for popular machine learning frameworks, and integration with other AWS services.
            Amazon Rekognition
            Amazon Rekognition is an image and video analysis service that can be used for tasks such as object and scene detection, facial analysis, and moderation of image and video content.
            It can be easily integrated into applications such as security systems, media platforms, and customer service chatbots.
            Amazon Transcribe
            Amazon Transcribe is a real-time speech-to-text service that can be used to transcribe audio and video files in multiple languages.
            It can also be used for tasks such as speaker identification, language identification, and transcription of multiple speakers in a single audio file.
            Amazon Translate
            Amazon Translate is a neural machine translation service that can be used to translate text from one language to another.
            It supports a wide range of languages and can be integrated into applications such as chatbots, e-commerce platforms, and customer service systems.
            Amazon Comprehend
            Amazon Comprehend is a natural language processing service that can be used for tasks such as sentiment analysis, language detection, and named entity recognition.
            It can also be used to extract insights from unstructured text data such as customer reviews and social media posts.
            Conclusion
            AWS AI services provide a powerful and easy-to-use platform for organizations of all sizes to leverage the power of artificial intelligence.
            Whether you're a developer, data scientist, or business leader, there's something for everyone in the AWS AI portfolio.`
        },
        {
            'title': 'User Experience',
            'img': './../imgs/icon/ux.svg',
            'descriptioin': 'If you’re building a mobile app, website, or software, you’re not just building it for yourself — you’re building it for the users and your target audience. We specialize in helping companies build their visions from scratch or initiate a makeover to an existing vision. Utilizing wireframes, clickable prototypes, task and decision flow charts, quality assurance testing, and interviewing stakeholders that have shown interest, your software will be user-ready for a pleasant experience.'
        },
        {
            'title': 'Project Management',
            'img': './../imgs/icon/pm.svg',
            'descriptioin': 'If you want your project to run smoothly and end successfully, it will take a quality team of experts to plan, strategize, control, execute, initiate, and finish the tasks at hand. We’ll adequately take your vision and seamlessly bring it to life.             When managing your project, we’ll make sure the design, progress, timeframe, scope, and budget are taken into heavy consideration to ensure your goals are ultimately reached. Most importantly, we’ll keep you in the loop from start to finish so you constantly know where your project currently stands.'
        },
        {
            'title': 'Web Development',
            'img': './../imgs/icon/data.svg',
            'descriptioin': 'Your opportunities are endless when building a website to showcase everything your business offers its customers and clients. User experience will be a top priority, which will require a unique and versatile approach when designing the website. With front-end and back-end considerations, we make your website easy to navigate for both you and your visitors.'
        },
        {
            'title': 'Mobile Development',
            'img': './../imgs/icon/code.svg',
            'descriptioin': 'Building a mobile app or mobile web page for your product, service, or idea will take careful consideration on a case-by-case basis. Since every project is unique, we can help you navigate your options between a native app or a hybrid app. From the analysis stage to the development stage and beyond, we’ll be with you every step of the way to provide expert guidance.'
        },
        {
            'title': 'Quality Assurance',
            'img': './../imgs/icon/qa.svg',
            'descriptioin': 'There’s a lot that goes into ensuring your software or app is functioning properly before releasing it out to the world. Our team of professionals perform a variety of tests designed to identify the issues, fix them, and prevent them from happening again. We specialize in manual testing, performance testing, automation engineering, compatibility testing, and continuous integration. If you’re looking to limit any delays or bugs when releasing your software or app, quality assurance will help you do so.'
        },
        {
            'title': 'Application Programming Interface (API)',
            'img': './../imgs/icon/api.svg',
            'descriptioin': 'We carry out integration with third-party services, as well as create our own API, both for integration between projects and for communication with front-end applications on various platforms (web, Android, iOS).'
        },
    ];
    return (
        <Layout>
            <div className="service__section">
                <div className="page_header">
                    <h3>Our Services</h3>
                </div>
                <Container>
                    <Row>
                        <Col md={10} className="mx-auto">

                            {data.map((ser, i) => (
                                (i % 2 === 0) ? <Row className="service_item" data-aos="flip-right" data-aos-duration="1000">
                                    <Col md={4} className="p-0">
                                        <div className="img_section">
                                            <img src={ser.img} alt="" className="img-fluid" />
                                            <p>{ser.title}</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div className={`${show === i ? 'service_text_auto' : 'service_text'}`}>
                                            {ser?.descriptioin?.length > 700 && show !== i ?
                                                <>
                                                    <h6>{ser?.heading}</h6>
                                                    <p>{ser?.descriptioin?.slice(0, 700) + ' ...'}</p>
                                                    <span onClick={e => setShow(i)}>See More</span>
                                                </>
                                                :
                                                <>
                                                    <p>{ser?.descriptioin}</p>
                                                    {show === i ? <span onClick={e => setShow('')}>Less</span> : ''}
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row> :
                                    <Row className="service_item" data-aos="flip-left" data-aos-duration="1000">
                                        <Col md={8}>
                                            <div className={`${show === i ? 'service_text_auto' : 'service_text'}`}>
                                                {ser?.descriptioin?.length > 700 && show !== i ?
                                                    <>
                                                        <h6>{ser?.heading}</h6>
                                                        <p>{ser?.descriptioin?.slice(0, 700) + ' ...'}</p>
                                                        <span onClick={e => setShow(i)}>See More</span>
                                                    </>

                                                    :
                                                    <>
                                                        <p>{ser?.descriptioin}</p>
                                                        {show === i ? <span onClick={e => setShow('')}>Less</span> : ''}
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                        <Col md={4} className="p-0">
                                            <div className="img_section">
                                                <img src={ser.img} alt="" className="img-fluid" />
                                                <p>{ser.title}</p>
                                            </div>
                                        </Col>
                                    </Row>


                            ))}


                        </Col>
                    </Row>
                </Container>

            </div>
        </Layout>
    )
}

export default Services
