import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';

const OurProduct = () => {
    return (
        <Layout>

            <div className="ourproduct__section">
                <div className="page_header">
                    <h3>Our Products</h3>
                </div>
                <Container>
                    <h5 className="ptags">We have been working internationally on different on demand products in different countries providing solutions of
                        complex problems to different businesses in <span>Central London, Melbourne Australia, United states, Malysia and Abu
                            Dhabi</span>  and several products in Pakistan in the form of solute ons for various businesses. The overview of some of our
                        products is given.</h5>


                    <div className="pro__section">
                        <Row>
                            <Col md={3} data-aos="zoom-in" data-aos-duration="1000">
                                <Card >
                                    <Card.Img variant="top" src="./../imgs/door.png" />
                                    <Card.Body>
                                        <Card.Title>E-Commerce</Card.Title>
                                        <Card.Text>
                                            The Omni Door is service provider to all aspects of Human needs. It is servicing the community in digital way by bringing ease in lives and providing digital solutions to ...
                                            <br />
                                            <Link className="text-primary" to="/productdetail/ecom">Read More..</Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
                                <Card >
                                    <Card.Img variant="top" src="./../imgs/mart.png" />
                                    <Card.Body>
                                        <Card.Title>Grocery & Medicine</Card.Title>
                                        <Card.Text>
                                            The Omni Mart is a platform that helps in connecting Customer with nearest Mart. We’re actually the aing platform for helping the Customer to find his near by mart on map. The ...
                                            <br />
                                            <Link className="text-primary" to="/productdetail/mart">Read More..</Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">
                                <Card >
                                    <Card.Img variant="top" src="./../imgs/tutor.png" />
                                    <Card.Body>
                                        <Card.Title>Education</Card.Title>
                                        <Card.Text>
                                            The Omni Tutor is a platform that helps in connecting Students with qalified Tutors. We’re actually the aing platform
                                            for helping the students to find his near by tutor on map...
                                            <br />
                                            <Link className="text-primary" to="/productdetail/tutor">Read More..</Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} data-aos="zoom-in" data-aos-delay="500" data-aos-duration="1000">
                                <Card >
                                    <Card.Img variant="top" src="./../imgs/barber.png" />
                                    <Card.Body>
                                        <Card.Title>Barbers & Beauty</Card.Title>
                                        <Card.Text>
                                            Were the platform providers which as Barbers and Customers. Our
                                            platform help barbers to get booked for multiple appointments easily and
                                            digitally. All the service providers ...
                                            <br />
                                            <Link className="text-primary" to="/productdetail/barber">Read More..</Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>

        </Layout>
    )
}

export default OurProduct
