import React, { useState, useEffect } from 'react';
import Layout from './../../../components/layout/Layout';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Table, Spin, message, Popover, Button } from 'antd';

const PlaceOrder = () => {

    const [item, setItem] = useState({ name: '', unit: '', quantity: '', company: 'Any' });
    let [itemlist, setItemlist] = useState([]);
    const history = useNavigate();
    // items adding
    const handleChange = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value });

    }
    localStorage.setItem('itemlist', '')
    // items Add to array
    const AddToList = (e) => {
        e.preventDefault();
        if (item.name === '' || item.unit === '' || item.quantity === '') {
            message.error("Some Value is messing Please Fill That")
        } else {

            itemlist.push(item);
            setItemlist(itemlist);
            setItem({ name: '', unit: '', quantity: '', company: 'Any' });
        }
    }

    // Submit Orders
    const SubmitOrder = (e) => {
        e.preventDefault();
        localStorage.setItem('itemlist', JSON.stringify(itemlist));
        history('/nearest-mart');
    }

    const ProductDetails = (
        <div>
            <p>Drinks (1.5 ltr) / Pack (250gm) / Length (12cm)</p>
        </div>
    );

    const Units = (
        <div>
            <p>Ltr / Kg / Mg / Box / Packet etc</p>
        </div>
    );

    const Quantity = (
        <div>
            <p>Specify the number of item e.g 1,2,3</p>
        </div>
    );

    const BrandName = (
        <div>
            <p>Shan / Coke / K&N's</p>
        </div>
    );

    const tableColumn = [
        {
            title: '#',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Description',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Brand',
            dataIndex: 'company',
            key: 'company',


        }
    ];

    const gridData = itemlist.map((tabData, i) => {
        return {
            ...tabData,
            sno: i + 1,
        };
    });

    return (
        <>
            <Layout>
                <div className="top_div">
                    <Container>
                        <Row>
                            <Col md={{ span: 8, offset: 2 }} className="place-order">
                                <div className="form_section " style={{ width: "100%" }}>
                                    <h4 className="mb-3 mt-3">List Your Order</h4>
                                    <Row>
                                        <Col md={4}>
                                            <Popover content={ProductDetails} title="Details" trigger="focus">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="text" name="name" value={item.name} onChange={handleChange} placeholder="Details e.g Drinks (1.5 ltr)" />
                                                </Form.Group>
                                            </Popover>

                                        </Col>
                                        <Col md={2}>
                                            <Popover content={Units} title="Standerd Unit" trigger="focus">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="text" name="unit" value={item.unit} onChange={handleChange} placeholder="Unit" />
                                                </Form.Group>
                                            </Popover>
                                        </Col>
                                        <Col md={3}>
                                            <Popover content={Quantity} title="Quantity" trigger="focus">
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control type="number" name="quantity" value={item.quantity} onChange={handleChange} placeholder="Quantity" />
                                                </Form.Group>
                                            </Popover>
                                        </Col>
                                        <Col md={3}>
                                            <Popover content={BrandName} title="Brand Name" trigger="focus">
                                                <Form.Group className="mb-3" >
                                                    <Form.Control type="text" name="company" value={item.company} onChange={handleChange} placeholder="Brand Name" />
                                                </Form.Group>
                                            </Popover>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={{ span: 4, offset: 4 }} className="mt-3 d-grid ">
                                            <Button variant="success" type="primary" onClick={AddToList}>
                                                Add To List
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <Row>
                                    <Col md={12} className="mt-3">

                                        {(!itemlist) ? <Spin /> :
                                            <>
                                                <Table
                                                    columns={tableColumn}
                                                    dataSource={gridData}
                                                    pagination={false}
                                                    rowKey={(record) => record.id}
                                                />

                                            </>
                                        }

                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={{ span: 4, offset: 4 }} className="mt-3 d-grid ">
                                        {(itemlist.length > 0) ?
                                            <Button type="primary" onClick={SubmitOrder}>
                                                Place Order
                                            </Button>
                                            : ''}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </div>

            </Layout>

        </>
    )
}

export default PlaceOrder
