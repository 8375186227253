import React, { useState, useEffect } from 'react'
import { Table, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faStar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Http from '../../../Http/CustomerApi';

const PendingOrder = () => {


    const [load, setLoading] = useState(false);

    const [data, setData] = useState([]);
    useEffect(() => {
        GetData();
    }, []);



    const GetData = () => {
        setLoading(false);
        Http.GetMyOrders().then((res) => {
            setLoading(true);
            if (res) {
                setData(res || []);
            }
        });
    }
    const tableColumn = [
        {
            title: 'OrderId',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Mart  Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact #',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Link to={'/mart_order_detail/' + record.id} className="btn  btn-sm">
                        <FontAwesomeIcon icon={faFile} />
                    </Link>

                    {(record.status === 'Delivered') ? (
                        <>
                            {record.status === 'Review' ? '' :

                                <Link to={'/customerreview/' + record.id} className="btn  btn-sm">
                                    <FontAwesomeIcon icon={faStar} />
                                </Link>
                            }
                        </>
                    ) : ''}
                </>
            )


        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).created_at || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        return {
            ...tabData,
            name: ((tabData || {}).mart || {}).name || 'No Assign',
            phone: ((tabData || {}).mart || {}).contact || 'No Assign',
            created_at: formattedDate,
        };
    });

    return (
        <>
            <div className="table_section">
                <h4>Pending Orders</h4>
                {(!load) ? <Spin /> : <Table
                    columns={tableColumn}
                    dataSource={gridData}
                    rowKey={(record) => record.id}
                />}

            </div>
        </>
    )
}

export default PendingOrder
