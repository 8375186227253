import React from 'react'
import Layout from '../../components/layout/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import LabDashboardData from './LabDashboardData';
import LabOrder from './LabOrders';

const LaboratoryDashboard = () => {
    return (
        <>
            <Layout>
                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">

                            <Row>
                                <Col>
                                    <LabDashboardData />
                                    <LabOrder />
                                </Col>
                            </Row>

                        </div>
                    </Container>

                </div>
            </Layout>

        </>
    )
}

export default LaboratoryDashboard
