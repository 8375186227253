import React, { useState, useEffect } from 'react';
import Layout from './../../../components/layout/Layout';
import { Row, Col, Container, } from 'react-bootstrap';
import BarberMap from './../element/BarberMap';
import LocationInputs from './../../../components/element/LocationInputs';
import CurrentLocation from './../element/CurrentLocation';
import { Rate, Button } from 'antd';
import Http from './../../../Http/CustomerApi';
import { Link } from 'react-router-dom';

const NearestBarber = () => {

    const currentLocation = CurrentLocation();
    const [updatelocation, setUpdateLocation] = useState();
    const location = updatelocation ? updatelocation : currentLocation;
    const [barbers, setBarber] = useState([]);

    useEffect(() => {
        GetData(location);
    }, [location]);

    const GetData = (location) => {

        Http.GetNearByBarber(location).then((res) => {
            if (res && Array.isArray(res)) {
                setBarber(res);
            } else {
                setBarber([]);

            }
        });
    }




    return (
        <>
            <Layout>
                <div className="top_div">
                    <Container>
                        <Row>

                            <Col md={9}>

                                <Row>
                                    <Col md={6} className="pin_location">
                                        <LocationInputs setLocation={setUpdateLocation} />
                                    </Col>

                                </Row>
                                <BarberMap location={location} barbers={barbers} />
                            </Col>
                            <Col md={3}>
                                {barbers ? barbers.map((data, i) => (
                                    <div className="--mart" key={i}>
                                        <h5>{data.name}</h5>
                                        <p>{data.address}</p>
                                        <h6>Rating <span><Rate disabled allowHalf defaultValue={parseFloat(data?.ratings[0]?.rating).toFixed(1)} style={{ fontSize: '10px', marginLeft: '1px' }} />&nbsp; ({data?.ratings[0]?.reviews || 0})</span></h6>
                                        <Link to={'/barber-detail/' + data.id}>
                                            <Button type="primary" block>Booked Barber</Button>
                                        </Link>

                                    </div>


                                )) :
                                    <div className="--mart">
                                        <h5>No Mart Register </h5>
                                        <p>We Are Looking best For You</p>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

            </Layout>

        </>
    )
}

export default NearestBarber
