import React, { useState, useEffect } from 'react'
import { Table, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Http from '../../../Http/MartApi';


const AllOrder = () => {

    const [load, setLoading] = useState(false);

    const [data, setData] = useState([]);
    useEffect(() => {
        GetData();
    }, []);



    const GetData = () => {
        setLoading(false);
        Http.GetTotalOrders().then((res) => {
            setLoading(true);
            if (res) {
                setData(res || []);
            }
        });
    }
    const tableColumn = [
        {
            title: 'Order_id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Customer  Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Billing Amount',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) =>
                <Link to={'/order_invoice/' + record.id} className="btn  btn-sm">
                    <FontAwesomeIcon icon={faFile} />
                </Link>

        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).created_at || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        return {
            ...tabData,
            name: ((tabData || {}).customer || {}).name || '',
            phone: ((tabData || {}).customer || {}).contact || '',
            created_at: formattedDate,
        };
    });

    return (
        <>
            <div className="table_section">
                <h4>All Orders </h4>
                {(!load) ? <Spin /> : <Table
                    columns={tableColumn}
                    dataSource={gridData}
                    rowKey={(record) => record.id}
                />}

            </div>

        </>
    )
}

export default AllOrder
