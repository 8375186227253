import React, { useState, useEffect } from 'react'
import { Table, Spin, Popconfirm, Button, message } from 'antd';
import moment from 'moment';
import Http from '../../../Http/MartApi';

const Notification = () => {
    const [load, setLoading] = useState(false);

    const [data, setData] = useState([]);
    useEffect(() => {
        GetData();
    }, []);



    const GetData = () => {
        setLoading(false);
        Http.GetNotificationsOrders().then((res) => {
            setLoading(true);
            if (res) {
                setData(res || []);
            }
        });
    }

    function confirm(id) {
        Http.ChangeStatusToPending(id).then((res) => {
            if (res.success) {
                setLoading(false)
                message.success(res.message);
                GetData();
            }
        });
    }

    function cancel(e) {
        console.log(e);
        message.error('Click on No');
    }

    const tableColumn = [
        {
            title: 'Order_id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Customer  Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button size="small" type="button" title="Completed"> <Popconfirm
                    title="Are you sure to Accept this Order?"
                    onConfirm={(e) => { confirm(record.id) }}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    Accept Order
                </Popconfirm></Button>

            )

        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).created_at || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        return {
            ...tabData,
            name: ((tabData || {}).customer || {}).name || '',
            phone: ((tabData || {}).customer || {}).contact || '',
            created_at: formattedDate,
        };
    });

    return (
        <>
            <div className="table_section">
                <h4>Notification Orders </h4>
                {(!load) ? <Spin /> : <Table
                    columns={tableColumn}
                    dataSource={gridData}
                    rowKey={(record) => record.id}
                />}

            </div>

        </>
    )
};

export default Notification;
