import setAuthToken from "./../../Http/SetAuthToken";

import { SET_CURRENT_USER, USER_LOADING, USER_LOGOUT } from "./types";
// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

// Log user out
export const logoutUser = () => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("type");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    // dispatch(setCurrentUser({}));
    return {
        type: USER_LOGOUT,
    };
};