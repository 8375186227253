import React from 'react';
const Service = () => {
    return (
        <>
            <div className="__services">
                <div className="heading">
                    <h1 data-aos="zoom-in" data-aos-duration="1000">Services We Provide</h1>
                    <p data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">Map a strategy, create a user friendly product experience, deliver reliable cross-platform
                        solution with on-boarding and staff training.</p>
                </div>

                <div className="serives_sub">
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="100" data-aos-duration="1000">
                        <img src="./imgs/icon/ai.svg" alt="services" fluid="" />
                        <h5>Artificial Intelligence (AI)</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000">
                        <img src="./imgs/icon/api.svg" alt="services" fluid="" />
                        <h5>Application Programing Interface</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="300" data-aos-duration="1000">
                        <img src="./imgs/icon/data.svg" alt="services" fluid="" />
                        <h5>Web Development</h5>
                    </div>
                    {/* <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="400" data-aos-duration="1000">
                        <img src="./imgs/icon/board.svg" alt="services" fluid="" />
                        <h5>On-Boarding / <br /> Staff Training</h5>
                    </div> */}
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="500" data-aos-duration="1000">
                        <img src="./imgs/icon/consult.svg" alt="services" fluid="" />
                        <h5>Strategic Consulting</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="600" data-aos-duration="1000">
                        <img src="./imgs/icon/code.svg" alt="services" fluid="" />
                        <h5>Mobile Development</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="700" data-aos-duration="1000">
                        <img src="./imgs/icon/pm.svg" alt="services" fluid="" />
                        <h5>Project Management</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="800" data-aos-duration="1000">
                        <img src="./imgs/icon/qa.svg" alt="services" fluid="" />
                        <h5>Quality Assurance</h5>
                    </div>
                    <div className="serives_sub_item" data-aos="slide-right" data-aos-delay="900" data-aos-duration="1000">
                        <img src="./imgs/icon/ux.svg" alt="services" fluid="" />
                        <h5>User Experience</h5>
                    </div>
                </div>

                {/* <Button variant="outline-success" className="btn expend">Explore All</Button> */}

            </div>

        </>
    )
}

export default Service
