import React, { useState } from 'react';
import Layout from '../../../components/layout/Layout';
import { Container, Row, Col, Tab, Nav, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTh, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';


import AllOrder from './AllOrder';
import PendingOrder from './PendingOrder';

const LabDashboard = () => {

    const [data, setData] = useState();
    return (
        <>
            <Layout>
                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">


                            <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard">
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="dashboard"><FontAwesomeIcon icon={faTh} />  Dashboard</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="pending"><FontAwesomeIcon icon={faShoppingBasket} /> Pending Orders</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link eventKey="allorder"><FontAwesomeIcon icon={faShoppingCart} /> All Orders</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="dashboard">

                                                <h4>Dashboard</h4>
                                                <div className="widges_design">
                                                    <Row className="top_row">
                                                        <Col md={3}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Card.Title>Total Orders</Card.Title>
                                                                    <Card.Text>{data?.total}</Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Card.Title>Pending Order</Card.Title>
                                                                    <Card.Text>{data?.pending}</Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Card.Title>Completed Orders</Card.Title>
                                                                    <Card.Text>{data?.completed}</Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Card>
                                                                <Card.Body>
                                                                    <Card.Title>Review Orders</Card.Title>
                                                                    <Card.Text>{data?.pending}</Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="pending">
                                                <PendingOrder />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="allorder">
                                                <AllOrder />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                        </div>
                    </Container>

                </div>
            </Layout>
        </>
    )
}

export default LabDashboard
