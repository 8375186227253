import { ADD_ITEM, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY, ORDER_PLACED } from '../action/types';



const initState = {
    addedItems: [],

}
export const cartReducer = (state = initState, action) => {


    if (action.type === ADD_ITEM) {

        action.payload.quantity = action.values.quantity;
        action.payload.name = action.values.name;
        action.payload.unit = action.values.unit;
        action.payload.company = action.values.company;

        return {
            ...state,
            addedItems: [...state.addedItems, action.payload],
        }
    }

    if (action.type === REMOVE_ITEM) {

        let itemToBeRemoved = state.addedItems.find(item => action.payload.id === item.id);
        let new_items = state.addedItems.filter(item => action.payload.id !== item.id);

        let newTotal = state.total - (itemToBeRemoved.price * itemToBeRemoved.quantity);
        const totalItemsAdded = state.addedItems.reduce(
            (accumulator, item) => accumulator + Number(item.quantity),
            0,
        );
        return {
            ...state,
            addedItems: new_items,
            total: newTotal,
            totalProduct: totalItemsAdded,
        }
    }

    if (action.type === ADD_QUANTITY) {

        let item = state.addedItems.find(item => item.id === action.payload.id);
        item.quantity += 1;
        let newTotal = state.total + item.disc_price;
        return {
            ...state,
            total: newTotal,
            totalProduct: state.totalProduct + 1,
        }
    }

    if (action.type === SUB_QUANTITY) {
        // if the quantity is equal to 1 then remove the item from cart
        let item = state.addedItems.find(item => item.id === action.payload.id);
        if (item.quantity === 1) {
            //remove it
            let newItems = state.addedItems.filter(item => item.id !== action.payload.id);
            let newTotal = state.total - item.disc_price;
            return {
                ...state,
                addedItems: newItems,
                total: newTotal,
                totalProduct: state.totalProduct - 1,
            }
        } else {
            let newTotal = state.total - item.disc_price;
            item.quantity -= 1;
            return {
                ...state,
                total: newTotal,
                totalProduct: state.totalProduct - 1,
            }
        }

    }

    if (action.type === ORDER_PLACED) {

        return {
            ...state,
            addedItems: [],
            total: 0,
            totalProduct: 0,
        }


    }

    return state;
}
export default cartReducer;