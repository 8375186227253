import React, { useState, useEffect } from 'react'
import Layout from '../../../components/layout/Layout';
import { Container, Row, Col, ListGroup, InputGroup, Form } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import Http from '../../../Http/CustomerApi';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';
import DatePicker from 'react-datepicker'
import moment from 'moment';
import { setMinutes, setHours } from 'date-fns';



const BarberDetail = () => {

    const [profile, setProfile] = useState();
    const [services, setService] = useState([]);
    const [amount, setAmount] = useState({ amount: 0, items: 0 });
    const [startDate, setStartDate] = useState(new Date());
    const [slotsTime, setSlotsTime] = useState();
    const [serviceItem, setServiceItem] = useState([]);
    const [values, setValues] = useState({ app_type: '', type: '' });
    const [loading, setLoding] = useState(false);
    const [error, setError] = useState([]);
    // const [appointments, setAppointments] = useState([]);
    const [excludeTime, setExcludeTime] = useState([]);
    const [isBookingDisabled, SetIsBookingDisabled] = useState(false);


    let { id } = useParams();
    const history = useNavigate();

    const [current, setCurrent] = useState({ vender: id, date: moment() });


    useEffect(() => {
        BarberProfile(id);
        GetServices()
    }, [id])






    const BarberProfile = (id) => {
        Http.GetBarberDetail(id).then((res) => {
            if (res) {
                setProfile(res);
            } else {
                setProfile({});
            }
        });
    }

    const GetServices = () => {
        Http.GetBarberServices().then((res) => {
            if (res && Array.isArray(res)) {
                setService(res);
            } else {
                setService([]);
            }
        });
    }

    //Take a service
    const handleTaken = (id) => {
        if (amount.items === 3) {
            message.error("Please Select Your 3 Best Services");
        } else {
            const updateServices = services.map((taken) => {
                if (taken.id === id) {
                    taken.is_selected = true;
                    let UpdatAamount = taken.price + amount.amount;
                    let UpdateItem = amount.items + 1;
                    let items = serviceItem;
                    items.push(taken.id)
                    setServiceItem(items)
                    setAmount({ amount: UpdatAamount, items: UpdateItem })
                }
                return taken;
            })
            setService(updateServices);

        }
    }

    // Remove Taken Service 
    const handleRemove = (id) => {
        const updateServices = services.map((taken) => {
            if (taken.id === id) {
                taken.is_selected = false;
                let UpdatAamount = amount.amount - taken.price;
                let UpdateItem = amount.items - 1;
                const index = serviceItem.indexOf(taken.id);
                if (index > -1) {
                    const newarray = serviceItem.splice(index, 1);
                    setServiceItem(newarray)
                }
                setAmount({ amount: UpdatAamount, items: UpdateItem })
            }
            return taken;
        }

        )
        setService(updateServices);

    }

    const filterPassedTime = (time) => {
        let selectedDate = new Date(time);
        const currentDate = new Date();
        hanldeBlur(time)
        return currentDate.getTime() < selectedDate.getTime();
    };


    const hanldeBlur = (date) => {
        let timemake = moment(date)
            .add(amount.items * 15, 'minutes')
            .format('YYYY-MM-DD HH:mm');
        setSlotsTime(timemake)
    }



    // submit methods

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoding(true);
        const data = {
            vender_id: id,
            amount: amount.amount,
            lists: serviceItem,
            start_time: moment(startDate).format('YYYY-MM-DD HH:mm'),
            date: moment(startDate).format('YYYY-MM-DD'),
            end_time: slotsTime,
            slots: amount.items,
            service_type: values.type,
            appType: values.app_type
        }
        TakeAppointment(data)
    }

    const TakeAppointment = async (data) => {

        const resp = await Http.TakeAppointment(data).then((res) => {
            return res;
        });

        if (resp && resp.success) {
            setLoding(false);
            message.success(resp.message);
            setValues({ app_type: '', type: '' });
            history('/customerbarberDashboard');

        } else {
            setLoding(false);
            message.error('Ooops Something Goes Wrong, Please Enter valid Data');
            if (resp) {
                setError(resp);
            }
        }
    }

    const TakeCurrentDateAppo = (data) => {
        const dataFormatted = {
            ...data,
            date: moment(data?.date).format('YYYY-MM-DD')
        }
        let Newslots = [];
        Http.TakeBarberAppointment(dataFormatted).then((res) => {
            // eslint-disable-next-line array-callback-return
            res.map((item) => {
                let slos = item.slots;
                for (let index = 0; index < slos; index++) {
                    let newdate = moment(item.start_time).add(index * 15, 'minutes').format('YYYY-MM-DD HH:mm');
                    Newslots.push(newdate)
                }
            }
            );
            setExcludeTime(Newslots);

        })

    }

    useEffect(() => {
        TakeCurrentDateAppo(current)
    }, [current]);

    const CurrentTimehandler = (date) => {
        setCurrent({ vender: id, date: moment(date) })
        hanldeBlur(date)
        handleBookSlote(date)
    }

    const handleBookSlote = (date) => {

        // Variable, play with the following variables,test results
        const slots = amount.items;
        const slotDuration = 15;
        const bookedSlots = excludeTime || [];



        // User Select Time
        const startTime = moment(date);
        const endTime = moment(date).add(slots * slotDuration, 'minutes')

        // Check if current slot is in between the booked slots
        let isCurrentSlotBooked = false
        // eslint-disable-next-line array-callback-return
        bookedSlots?.map((bookedSlotDate) => {
            isCurrentSlotBooked = isCurrentSlotBooked ? true : moment(moment(bookedSlotDate).toISOString()).isBetween(startTime.toISOString(), endTime.toISOString());
        }
        )
        if (isCurrentSlotBooked) {
            message.error("Please Select Another Time Slot")
        }

        SetIsBookingDisabled(isCurrentSlotBooked)

    }






    return (
        <>
            <Layout>
                <div className="customer_dashboard">
                    <Container>
                        <div className="dashboard_body">
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <div className="main_card">
                                                <div className="main_card_left">
                                                    <div className="img_div">
                                                        <div className="circle-1"></div>
                                                        <div className="circle-2"></div>
                                                        <img src="/imgs/profile.jpeg" alt="profile" />
                                                    </div>
                                                    <h4>{profile?.name}</h4>
                                                    <p>{profile?.contact}</p>
                                                </div>
                                                <div className="main_card_right">
                                                    <div className="right_item">
                                                        <h6>{parseFloat(profile?.ratings[0]?.rating).toFixed(1) || 0}</h6>
                                                        <h3>Rating</h3>
                                                    </div>
                                                    <div className="right_item">
                                                        <h6>{profile?.ratings[0]?.reviews || 0}</h6>
                                                        <h3>Reviews</h3>
                                                    </div>
                                                    <div className="right_item">
                                                        <h6>{profile?.appointments[0]?.appointments || 0}</h6>
                                                        <h3>Total Orders</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="item_list">
                                                <h4>Barber Service List</h4>
                                                <span className="text-danger">{error?.lists ? 'Please Select Services 1 to 3' : ''}</span>

                                                <ListGroup>
                                                    {
                                                        services.map((service, i) => (
                                                            <ListGroup.Item className={`${service.is_selected ? 'li_active' : ''}`} onClick={(e) => { if (!service.is_selected) { handleTaken(service.id) } else { handleRemove(service.id) } }} key={i} >{service.title}</ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="item_list2">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        setStartDate(date);
                                                        CurrentTimehandler(date)

                                                    }}
                                                    dateFormat="yyyy/MM/dd h:mm"

                                                    showTimeSelect
                                                    excludeTimes={
                                                        excludeTime.map((date, i) => (
                                                            setHours(setMinutes(new Date(date), new Date(date).getMinutes()), new Date(date).getHours())
                                                        ))
                                                    }
                                                    filterTime={filterPassedTime}
                                                    timeIntervals={15}
                                                />
                                                <span className="text-danger">{(error?.end_time || error?.end_time) ? "Please Select Time Slote" : ''}</span>

                                                <InputGroup >
                                                    <Form.Select aria-label="Default select" name="type" value={values.type} onChange={handleChange}>
                                                        <option>Select Service Type</option>
                                                        <option value="Adult">Adult</option>
                                                        <option value="Child">Child</option>
                                                    </Form.Select>
                                                </InputGroup>
                                                <span className="text-danger">{error?.service_type}</span>
                                                <InputGroup>
                                                    <Form.Select aria-label="Default select" name="app_type" value={values.app_type} onChange={handleChange}>
                                                        <option>Select Appointment Type</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Personal">Personal</option>
                                                    </Form.Select>
                                                </InputGroup>
                                                <span className="text-danger">{error?.appType}</span>

                                                {(!loading) ?
                                                    <div className="d-grid">
                                                        <Button onClick={handleSubmit} type="primary" disabled={isBookingDisabled}>Book Appointment</Button>
                                                    </div>
                                                    :

                                                    <div className="d-grid">
                                                        <Button type="button" disabled>
                                                            <LoadingOutlined style={{ fontSize: 15 }} spin /> Loading...
                                                        </Button>
                                                    </div>

                                                }


                                            </div>
                                        </Col>

                                    </Row>

                                </Col>
                            </Row>
                        </div>

                    </Container>
                </div>

            </Layout >

        </>
    )
}

export default BarberDetail