import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Http from '../../../Http/BarberApi';

const PendingOrder = () => {


    const [load, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        GetData();
    }, []);




    const GetData = () => {
        setLoading(false)
        Http.GetNewBarberOrders().then((res) => {
            if (res) {
                setLoading(true)
                setData(res || []);
            }
        });
    }

    const handleApprove = (id) => {
        setLoading(true)
        Http.ChangeStatusToApprove(id).then((res) => {
            if (res.success) {
                setLoading(false)
                message.success(res.message);
                GetData();
            }
        });
    }


    const tableColumn = [
        {
            title: 'Order_id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'BarberShop',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Time Slot',
            dataIndex: 'timeslot',
            key: 'timeslot',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="link" href={'/barberappointmentdetail/' + record.id}><FontAwesomeIcon icon={faFile} /></Button>

                    {(record.status === 'Pending') ? (
                        <Button size="small" type="button" title="Approve" onClick={(e) => handleApprove(record.id)} > Approve</Button>

                    ) : ''}
                </>
            )


        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).start_time || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        const time = moment(tabData.start_time).format('hh:mm a') + ' / ' + moment(tabData.end_time).format('hh:mm a');
        return {
            ...tabData,
            name: ((tabData || {}).customer || {}).name || 'No Assign',
            phone: ((tabData || {}).customer || {}).contact || 'No Assign',
            created_at: formattedDate,
            timeslot: time,
        };
    });

    return (
        <>
            <div className="table_section">
                <h4>Pending Orders</h4>
                {(!load) ? <Spin /> : <Table
                    columns={tableColumn}
                    dataSource={gridData}
                    rowKey={(record) => record.id}
                />}

            </div>
        </>
    )
}

export default PendingOrder
