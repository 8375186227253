import React, { useState } from "react";
import {
    Form,
    InputGroup,
    FormControl,
    Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import Http from "../../../Http/MartApi";
import { message, Button } from 'antd';
import SetAuthToken from "../../../Http/SetAuthToken";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from "../../../redux/action/AuthAction";
import { useDispatch } from "react-redux";

const SignIn = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        email: "",
        password: "",
        status: "Active",
    });
    const [error, setError] = useState();
    const [loading, setLoding] = useState(false);

    // On Chage values  Store  Function
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    // Submit Form Function
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoding(true);
        CustomerSignIn(values);
    };

    const CustomerSignIn = async (data) => {
        const resp = await Http.MART_SIGN_IN(data).then((res) => {
            return res;
        });

        if (resp && resp.token) {
            setLoding(false);
            const { token, type } = resp;
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("type", 'Vender');
            localStorage.setItem("vender_type", type);
            SetAuthToken(token);
            const decoded = {
                token: token,
                type: 'Vender',
                vaner_type: type,
            };
            dispatch(setCurrentUser(decoded));
            // toast.success('LogIn Successfull...!');
            message.success('LogIn Successfull...!');
            setValues({ email: "", password: "" });
            // Toster & redirect To Signin
            if (type === 'MART') {
                history("/ven_mart_dash");
            } else if (type === 'LABORATORY') {
                history("/ven_lab_dash");
            } else if (type === 'BARBER') {
                history("/ven_barber_dash");
            } else {
                history("/ven_tutor_dash");
            }

        } else {
            setLoding(false);
            message.error('Ooops Something Goes Wrong, Please Enter valid Data');
            setError(resp);
        }
    };
    return (
        <>
            <h4>SignIn as Partner</h4>
            <Form onSubmit={handleSubmit}>
                <span className="text-danger">{(error?.email) ? error?.email : ''}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <FormControl
                        id="email"
                        placeholder="info@example.com"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                    />
                </InputGroup>
                <span className="text-danger">{(error?.password) ? error?.password : ''}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
                    <FormControl
                        id="password"
                        type="password"
                        placeholder="********"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                    />
                </InputGroup>
                <span className="text-danger">{(!error?.email) ? error : ''}</span>
                <div className="d-grid mb-3">
                    <Button type="primary" onClick={handleSubmit}>
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="visually-hidden">Loading...</span>
                            </>
                        ) : (
                            "LogIn"
                        )}
                    </Button>
                </div>

            </Form>
        </>
    )
}

export default SignIn
