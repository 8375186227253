import { BrowserRouter, Routes, Route } from 'react-router-dom';

/*
*********************
        CSS AREA
********************
*/
import './assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import 'react-datepicker/dist/react-datepicker.css'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles




/*
*********************
        PAGES IMPORT AREA
********************
*/
import Home from './pages/Home';
import Services from './pages/Services';
import OurProduct from './pages/OurProduct';
import ProductDetail from './pages/ProductDetail';
import About from './pages/About';
import Contact from './pages/Contact';
import Team from './pages/Team';

import CustomerUi from './pages/customer/CustomerUi';
import CustomerDashboard from './pages/customer/mart/MartDashboard';
import LaboratoryDashboard from './pages/customer/LaboratoryDashboard';
import CustomerReview from './pages/customer/mart/CustomerReview';
import PlaceOrder from './pages/customer/mart/PlaceOrder';
import NearestMart from './pages/customer/mart/NearestMart';
import MartPayment from './pages/customer/mart/MartPayment';

// Customer Barber Imports
import BarberDashboard from './pages/customer/barber/BarberDashboard';
import AppointmentDetail from './pages/customer/barber/AppointmentDetail';
import NearestBarber from './pages/customer/barber/NearestBarber';
import BarberDetail from './pages/customer/barber/BarberDetail';
import CustomerBarberReview from './pages/customer/barber/CustomerBarberReview';
import BarberPayment from './pages/customer/barber/BarberPayment';


import Success from './pages/customer/Success';


// vender

import BecoomePartner from './pages/vender/BecoomePartner';

import VenderMartDashboard from './pages/vender/mart/MartDashboard';
import MartOrderDetail from './pages/vender/mart/MartOrderDetail';
import OrderInvoice from './pages/vender/mart/OrderInvoice';
import VenderLabDashboard from './pages/vender/laboratory/LabDashboard';

// Berber Imports
import VenderBarberDashboard from './pages/vender/barber/BarberDashbaord';
import BarberAppointmentDetail from './pages/vender/barber/BarberAppointmentDetail';



import { Provider } from "react-redux";
import store from "./redux/store";
import { setCurrentUser } from "./redux/action/AuthAction";
import setAuthToken from './Http/SetAuthToken';
import jwt_decode from "jwt-decode";

import PrivateRoute from './Http/ProtectedRoute';

// Check for token to keep user logged in
// const authType = localStorage.type;
// if (localStorage.jwtToken) {
//   // Set auth token header auth
//   const token = localStorage.jwtToken;
//   setAuthToken(token);
//   // Decode token and get user info and exp
//   const decoded = jwt_decode(token);
//   // Set user and isAuthenticated
//   store.dispatch(setCurrentUser(decoded));
//   // Check for expired token
//   const currentTime = Date.now() / 1000; // to get in milliseconds

//   if (decoded.exp < currentTime) {
//     // Logout user

//     // store.dispatch(logoutUser());
//     localStorage.clear();
//     // Redirect to login
//     window.location.href = "./";
//   }
// }




function App() {
  AOS.init({
    easing: 'ease', // default easing for AOS animations
  });


  return (
    <Provider store={store}>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/ourproduct' element={<OurProduct />} />
          <Route path='/productdetail/:slug' element={<ProductDetail />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/team' element={<Team />} />

          {/* Customer Routes */}
          <Route path="/customerui" element={<PrivateRoute redirect="/" />}>
            <Route path='/customerui' element={<CustomerUi />} />
          </Route>
          <Route path="/customermartdashboard" element={<PrivateRoute redirect="/" />}>
            <Route path='/customermartdashboard' element={<CustomerDashboard />} />
          </Route>
          <Route path="/customerlabdashboard" element={<PrivateRoute redirect="/" />}>
            <Route path='/customerlabdashboard' element={<LaboratoryDashboard />} />
          </Route>
          <Route path="/customer-review/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/customer-review/:id' element={<CustomerReview />} />
          </Route>

          <Route path="/place-order" element={<PrivateRoute redirect="/" />}>
            <Route path='/place-order' element={<PlaceOrder />} />
          </Route>

          <Route path="/nearest-mart" element={<PrivateRoute redirect="/" />}>
            <Route path='/nearest-mart' element={<NearestMart />} />
          </Route>

          <Route path="/mart-payment/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/mart-payment/:id' element={<MartPayment />} />
          </Route>


          {/* Customer Barber Routes */}
          <Route path="/customerbarberDashboard" element={<PrivateRoute redirect="/" />}>
            <Route path='/customerbarberDashboard' element={<BarberDashboard />} />
          </Route>

          <Route path="/appointmentdetail/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/appointmentdetail/:id' element={<AppointmentDetail />} />
          </Route>
          <Route path="/customerbarberreview/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/customerbarberreview/:id' element={<CustomerBarberReview />} />
          </Route>


          <Route path="/nearest-barber" element={<PrivateRoute redirect="/" />}>
            <Route path='/nearest-barber' element={<NearestBarber />} />
          </Route>

          <Route path="/barber-detail/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/barber-detail/:id' element={<BarberDetail />} />
          </Route>

          <Route path="/barber-payment/:id" element={<PrivateRoute redirect="/" />}>
            <Route path='/barber-payment/:id' element={<BarberPayment />} />
          </Route>

          <Route path="/success" element={<PrivateRoute redirect="/" />}>
            <Route path='/success' element={<Success />} />
          </Route>


          {/* Customer Routes End */}


          {/* Vender Routes */}

          <Route path='/ven_sign_in' element={<BecoomePartner />} />

          {/* Mart Route */}
          <Route path="/ven_mart_dash" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/ven_mart_dash' element={<VenderMartDashboard />} />
          </Route>
          <Route path="/mart_order_detail/:id" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/mart_order_detail/:id' element={<MartOrderDetail />} />
          </Route>
          <Route path="/order_invoice/:id" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/order_invoice/:id' element={<OrderInvoice />} />
          </Route>


          {/* Laboratory Route */}
          <Route path="/ven_lab_dash" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/ven_lab_dash' element={<VenderLabDashboard />} />
          </Route>



          {/* BARBER ROUTES */}
          {/* <Route path="" element={<PrivateRoute redirect="/" />}>
          </Route> */}

          <Route path="/ven_barber_dash" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/ven_barber_dash' element={<VenderBarberDashboard />} />
          </Route>
          <Route path="/barberappointmentdetail/:id" element={<PrivateRoute redirect="/ven_sign_in" />}>
            <Route path='/barberappointmentdetail/:id' element={<BarberAppointmentDetail />} />
          </Route>


        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
