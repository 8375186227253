import Axios from './index';

class CustomerApi {


    async CUSTOMER_SIGN_UP(data) {

        const reponse = await Axios.post('/customer/signup', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    async CUSTOMER_SIGN_IN(data) {

        const reponse = await Axios.post('/customer/signin', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }


    // Customer Mart Orders 
    async GetMyOrders() {

        const reponse = await Axios.get('customer/customer_mart_orders')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // GET Customer ORDERS ITEMS 
    async GetOrderItem(id) {
        const reponse = await Axios.get(`customer/customer_mart_orders_detail/${id}`)
            .then((response) => {
                return response.data.orderitems;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }




    // Give Rating to MartApi
    async GiveRatingToMart(data) {

        const reponse = await Axios.post('customer/give_mart_rating', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }



    // Customer Orders 
    async GetNearByMart(data) {
        const reponse = await Axios.post('customer/near_mart', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // GET CUSTOMER DASHBOARD DETAILS 
    async GetDashboardDetails() {
        const reponse = await Axios.get(`customer/customer_dashboard`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // // GET Order Details For Payments
    async GetOrderDetailForPayment(id) {
        const reponse = await Axios.get(`customer/get_order_for_payment/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    async CustomerPayToMart(data) {
        const reponse = await Axios.post('customer/customer_pay_to_mart', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }
    /*  **** 
    
    BARBER APIS FUNCTION
    
    
    */


    // // Customer Barber Orders 
    async GetMyBarberOrders() {

        const reponse = await Axios.get('customer/get_barber_appointments')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // // GET Customer ORDERS ITEMS
    async GetBarberOrderItem(id) {
        const reponse = await Axios.get(`customer/get_appointment_detail/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // // Get Nearest Barbers 
    async GetNearByBarber(data) {
        const reponse = await Axios.post('customer/get_nearest_barbers', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // // GET Barber Details
    async GetBarberDetail(id) {
        const reponse = await Axios.get(`customer/barber_profile/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // // GET Barber Services
    async GetBarberServices() {
        const reponse = await Axios.get(`customer/allservices`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // // Give Rating to BARBER 
    async GiveRatingToBarber(data) {

        const reponse = await Axios.post('customer/give_barber_rating', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }

    // // GET CUSTOMER DASHBOARD DETAILS 
    async GetBerberDashboardDetails() {
        const reponse = await Axios.get(`customer/customer_barber_dashboard`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // // Take Appointments
    async TakeAppointment(data) {
        const reponse = await Axios.post('customer/take_appointment_barber', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }

    // // Current Date Appointments
    async TakeBarberAppointment(data) {
        const reponse = await Axios.post('customer/take_appointment_barber_current_date', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // // GET Appointments Details For Payments
    async GetAppDetailForPayment(id) {
        const reponse = await Axios.get(`customer/get_appointment_for_payment/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // Customer Payment To Barber   
    async CustomerPayToBarber(data) {
        const reponse = await Axios.post('customer/customer_pay_to_barber', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }
}

export default new CustomerApi();
