import React, { useState } from 'react'
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

import Http from '../../Http/CustomerApi';
import SetAuthToken from './../../Http/SetAuthToken';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from './../../redux/action/AuthAction';
import { useDispatch } from 'react-redux';



const Signin = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const [values, setValues] = useState({ email: '', password: '', status: 'Active' });
    const [error, setError] = useState([]);
    const [loading, setLoding] = useState(false);

    // On Chage values  Store  Function
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }


    // Submit Form Function
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoding(true);
        CustomerSignIn(values);
    }

    const CustomerSignIn = async (data) => {

        const resp = await Http.CUSTOMER_SIGN_IN(data).then((res) => {
            return res;
        });

        if (resp && resp.token) {
            setLoding(false);
            const { token } = resp;
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("type", "Customer");
            SetAuthToken(token);
            const decoded = {
                "token": token,
                "type": "Customer"
            };
            dispatch(setCurrentUser(decoded));
            message.success('LogIn Successfull...!');
            setValues({ contact: '', password: '', });
            // Toster & redirect To Signin
            history('/customerui');

        } else {
            setLoding(false);
            message.error('Ooops Something Goes Wrong, Please Enter valid Data');
            setError(resp);

        }
    }

    return (
        <>
            <h4>SignIn</h4>
            <Form onSubmit={handleSubmit}>
                <span className="text-danger">{(error?.email) ? error?.email : ''}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                    <FormControl id="phone" placeholder="info@example.com" name="email" value={values.email} onChange={handleChange} isInvalid={(error?.email) ? error?.email : ''} />
                </InputGroup>
                <span className="text-danger">{(error?.password) ? error?.password : ''}</span>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                    <FormControl id="password" type="password" placeholder="********" name="password" value={values.password} onChange={handleChange} isInvalid={(error?.password) ? error?.password : ''} />
                </InputGroup>
                <span className="text-danger">{(!error?.email) ? error : ''}</span>
                {(!loading) ?
                    <div className="d-grid">
                        <Button type="submit">
                            SignIn
                        </Button>
                    </div>
                    :

                    <div className="d-grid">
                        <Button type="button" disabled>
                            <LoadingOutlined style={{ fontSize: 15 }} spin /> Loading...
                        </Button>
                    </div>

                }


            </Form>
        </>
    )
}

export default Signin
