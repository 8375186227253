import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { message } from 'antd';
import { Link } from 'react-router-dom';

const ProductSction = () => {
    const handlepop = () => {
        message.error("Please Sigin First");
    }
    return (
        <>
            <div className="productSection">
                <Container>
                    <Row>
                        <Col md={8} className="mx-auto">
                            <h1>Some Of Our Products</h1>


                            <Row>
                                <Col md={3} data-aos="zoom-in" data-aos-duration="1000">
                                    <Link className="text-primary" to="/productdetail/mart">
                                        <Card>
                                            <Card.Img variant="top" src="./../imgs/mart_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Grocery & Medicine</Card.Title>

                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000">
                                    <Link className="text-primary" to="/productdetail/barber">
                                        <Card>
                                            <Card.Img variant="top" src="./../imgs/barber_icon.png" />
                                            <Card.Body>
                                                <Card.Title>Barbers & Beauty</Card.Title>

                                            </Card.Body>
                                        </Card>
                                    </Link>

                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">

                                    <Card className="hidden_product">
                                        <Card.Img variant="top" src="./../imgs/lab_icon.png" />
                                        <Card.Body>
                                            <Card.Title>Health Care</Card.Title>
                                        </Card.Body>
                                    </Card>

                                </Col>

                                <Col md={3} data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">

                                    <Card className="hidden_product">
                                        <Card.Img variant="top" src="./../imgs/ecom_icon.png" />
                                        <Card.Body >
                                            <Card.Title>e-Commerce</Card.Title>
                                        </Card.Body>
                                    </Card>

                                </Col>
                                <Col md={3} data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000">

                                    <Card className="hidden_product">
                                        <Card.Img variant="top" src="./../imgs/tutor_icon.png" />
                                        <Card.Body>
                                            <Card.Title>Education</Card.Title>
                                        </Card.Body>
                                    </Card>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default ProductSction
