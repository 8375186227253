import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Http from '../../../Http/CustomerApi';
import { Link } from 'react-router-dom'

const MartDashboardData = () => {
    const [data, setData] = useState({ completed: 0, total: 0, pending: 0, review: 0 });

    useEffect(() => {
        GetData();
    }, []);


    const GetData = () => {
        Http.GetDashboardDetails().then((res) => {
            if (res) {
                setData({ completed: res.comp, total: res.total, pending: res.pend, review: res.review });
            }
        });
    }
    return (
        <>
            <Row>
                <Col md={10}>
                    <h4> Mart Dashboard</h4>
                </Col>
                <Col md={2}>
                    <Link to="/place-order" className="animated-button">Place Order</Link>
                </Col>
            </Row>
            <div className="widges_design">
                <Row className="top_row">
                    <Col md={3}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Total Orders</Card.Title>
                                <Card.Text>{data?.total}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Pending Order</Card.Title>
                                <Card.Text>{data?.pending}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Completed Orders</Card.Title>
                                <Card.Text>{data?.completed}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Review Orders</Card.Title>
                                <Card.Text>{data?.review}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>

        </>
    )
}

export default MartDashboardData
