import React, { useState, useEffect } from 'react'
import Layout from '../../../components/layout/Layout';
import { useParams } from "react-router-dom";
import Http from '../../../Http/CustomerApi';
import { Container, Row, Col, } from 'react-bootstrap';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyCheckoutForm from './../PaymentDetail'
const stripePromise = loadStripe("pk_test_51IUEm1Gp0YSIUkCJCxvVuSaBViQfDWT3INQs2e0gYApP1rPRMOSGkr2UCpn7zsGxdA408qHUfNP4ulin6ccVvT9V00xKOUJaS7");


const BarberPayment = () => {

    let { id } = useParams();
    const [detail, setDetail] = useState({ amount: 0, id: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getAppDetail() {
        Http.GetAppDetailForPayment(id).then((res) => {
            if (res) {
                setDetail({ amount: res, id: id });
            }
        });
    }

    useEffect(() => {
        getAppDetail();
    }, [id]);


    return (
        <Layout>
            <div className="customer_dashboard">
                <Container>
                    <div className="dashboard_body">
                        <Row>
                            <Col md={{ span: 6, offset: 3 }}>
                                <Elements stripe={stripePromise}>
                                    <MyCheckoutForm id={id} type="BARBER" amount={detail.amount} />
                                </Elements>

                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>

        </Layout>
    );
};

export default BarberPayment;
