import Axios from './index';

class VenderAuthApi {

    // MART SIGN UP
    async MART_SIGN_UP(data) {

        const reponse = await Axios.post('mart/signup', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.Error;
                }
            });
        return reponse;
    }


    // Mart SiNGIN 
    async MART_SIGN_IN(data) {

        const reponse = await Axios.post('mart/signin', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response.data.error;
                }
            });
        return reponse;
    }



}

export default new VenderAuthApi();
