import React, { useState, useEffect } from 'react'
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faStar, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Http from '../../../Http/CustomerApi';



const BarberOrder = () => {

    const { Text } = Typography;


    const [data, setData] = useState([]);
    useEffect(() => {
        GetData();
    }, []);



    const GetData = () => {
        Http.GetMyBarberOrders().then((res) => {
            if (res) {
                setData(res || []);
            }
        });
    }
    const tableColumn = [
        {
            title: 'Order_Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'BarberShop',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Time Slot',
            dataIndex: 'timeslot',
            key: 'timeslot',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: ' Pay Method',
            key: 'pay_status',
            render: (text, record) => (
                <>
                    <Text type="secondary">{record.pay_status}</Text>
                    {(record.status === 'Approve' && record.pay_status != "Paid") ? (
                        <Link to={'/barber-payment/' + record.id} className="btn btn-sm">
                            <FontAwesomeIcon icon={faCreditCard} title="You Want To Pay Online" />
                        </Link>
                    ) : ''}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Link to={'/appointmentdetail/' + record.id} className="btn  btn-sm">
                        <FontAwesomeIcon icon={faFile} />
                    </Link>

                    {(record.status === 'Completed') ? (
                        <>
                            {record.status === 'Review' ? '' :

                                <Link to={'/customerbarberreview/' + record.id} className="btn btn-sm">
                                    <FontAwesomeIcon icon={faStar} />
                                </Link>
                            }
                        </>
                    ) : ''}
                </>
            )


        },
    ];

    const gridData = data.map((tabData) => {
        const datetdate = (tabData || {}).start_time || '';
        const formattedDate = moment(datetdate).format('DD-MM-YYYY');
        const time = moment(tabData.start_time).format('hh:mm a') + ' / ' + moment(tabData.end_time).format('hh:mm a');
        return {
            ...tabData,
            name: ((tabData || {}).vender || {}).name || 'No Assign',
            phone: ((tabData || {}).vender || {}).contact || 'No Assign',
            created_at: formattedDate,
            timeslot: time,
        };
    });
    return (
        <>
            <div className="table_section">
                <h4>Barber Appointments</h4>
                <Table
                    columns={tableColumn}
                    dataSource={gridData}
                    rowKey={(record) => record.id}
                />

            </div>
        </>
    )
}

export default BarberOrder
