import React from 'react';
import Layout from '../components/layout/Layout';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { PhoneOutlined, WhatsAppOutlined, GooglePlusOutlined, EnvironmentOutlined } from '@ant-design/icons';
const Contact = () => {
    return (
        <Layout>
            <div className="page_header">
                <h3>Contact Us</h3>
            </div>

            <div className="contact__section">
                <Container>
                    <Row>
                        <Col md={10} className="mx-auto">
                            <Row>
                                <Col md={6}>
                                    <div className="form__section" data-aos="zoom-in" data-aos-duration="1000">
                                        <h4>Contact Us</h4>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Enter Name" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Subject" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Enter Email" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Control as="textarea" rows={4} />
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img_sec" data-aos="zoom-in" data-aos-duration="1000">
                                        <img src="./../imgs/contact.svg" alt="contact" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <div className="contact_second">
                    <Row>
                        <Col md={10} className="mx-auto">
                            <Row>
                                <Col md={3} data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="contact_item">
                                        <PhoneOutlined />
                                        {/* <h5>Mr. Abdul Wajid</h5>
                                        <p>+92336-9999787</p> */}
                                        <h5>Sayyed Asim Shah</h5>
                                        <p>+92333-9162888</p>
                                    </div>
                                </Col>
                                <Col md={3} data-aos="fade-in" data-aos-delay="200" data-aos-duration="1000">
                                    <div className="contact_item">
                                        <WhatsAppOutlined />
                                        {/* <h5>Mr. Abdul Wajid</h5>
                                        <p>+92336-9999787</p> */}
                                        <h5>Ahmed Shah Rasooli</h5>
                                        <p>+447481850121</p>
                                    </div>
                                </Col>
                                <Col md={3} data-aos="fade-in" data-aos-delay="400" data-aos-duration="1000">
                                    <div className="contact_item">
                                        <GooglePlusOutlined />
                                        <h5>OFFICIAL MAIL</h5>
                                        <p>info@infoaffinity.com</p>
                                        <h5>GOOGLE MAIL</h5>
                                        <p>infoaffinity@gmail.com</p>


                                    </div>
                                </Col>
                                <Col md={3} data-aos="fade-in" data-aos-delay="500" data-aos-duration="1000">
                                    <div className="contact_item">
                                        <EnvironmentOutlined />
                                        <h5>Address</h5>
                                        <p>Office: Suite 302, Unit 3, Watling Gate, 297-303  Edgware Road, London, NW9 6NB</p>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>





            </div>


        </Layout>
    )
}

export default Contact
