
import React, { useState } from 'react';
import Layout from "../../components/layout/Layout";
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
import SignIn from './module/SignIn';
import SignUp from './module/SignUp';

const BecoomePartner = () => {

    const [status, setStatus] = useState(true);
    const FormhandleShow = () => setStatus(!status);

    return (
        <Layout>
            <div className="top_div">
                <Container>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} className="form_main_section">
                            <div className="form_section">
                                <div className="logo">
                                    <img src="./imgs/logo.png" alt="logo" />
                                </div>
                                {(status) ? <SignIn /> : <SignUp />}

                                <a className="linkButton" onClick={FormhandleShow}>{(status) ? 'Create New Account..?' : 'I have Already account'}</a>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default BecoomePartner;
